import gql from 'graphql-tag'

export default gql`
  query getCoupons($filters: CouponFiltersInput, $pageInfo: PageInfo) {
    getCoupons(filters: $filters, pageInfo: $pageInfo) {
      id
      createdAt
      canceledAt
      code
      expiryDate
      multiAccount
      usesAvailable
      usesPerAccount
      note
      packageId
      redemptionCount
    }
  }
`
