import Button, { ButtonProps } from '@material-ui/core/Button'
import { RouterLink } from './RouterLink'
import React, { ReactNode } from 'react'

type Props = ButtonProps & {
  link: string
  children: ReactNode
}

const ButtonLink = ({ link, children, ...props }: Props) => {
  return (
    <RouterLink to={link}>
      <Button variant='contained' style={{ margin: '20px 5px 20px 5px', fontSize: '13px' }} {...props}>
        {children}
      </Button>
    </RouterLink>
  )
}

export default ButtonLink
