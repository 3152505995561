import adminListGroups from '../gql/queries/adminListGroups'
import { Typography } from '@material-ui/core'
import { GenericTable } from '../components'
import Layout from '../layouts/Layout'
import { formatDate } from '../Utils'
import CircularProgress from '@material-ui/core/CircularProgress'
import { AdminListGroupsQuery } from '../gql/__generated__'
import ButtonLink from '../components/ButtonLink'
import { useQuery } from '@apollo/client'

type Props = {}

const GroupListPage = (_props: Props) => {
  const { data, loading } = useQuery<AdminListGroupsQuery>(adminListGroups)

  const columns = [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: 'Name',
      accessor: 'name',
      link: (item: any) => `/groups/${item.id}`,
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'CreatedAt',
      accessor: (item: any) => {
        return formatDate(item.createdAt)
      },
    },
  ]

  if (loading) {
    return (
      <Layout>
        <CircularProgress size={20} thickness={5} />
      </Layout>
    )
  }

  return (
    <Layout>
      <Typography variant='h4'>Groups</Typography>
      <ButtonLink link='/groups/create'>Create Group</ButtonLink>
      {data && <GenericTable title='Groups' data={data.adminListGroups} columns={columns} />}
    </Layout>
  )
}

export default GroupListPage
