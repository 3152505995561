import { GenericTable } from './GenericTable'
import React from 'react'
import { Button } from '@material-ui/core'
import NotificationService from '../services/NotificationService'
import getPackagesQuery from '../gql/queries/getPackages'
import assignTutorToPackageMutation from '../gql/mutations/assignTutorToPackage'
import removeTutorFromPackageMutation from '../gql/mutations/removeTutorFromPackage'
import { AssignTutorToPackageMutation, AssignTutorToPackageMutationVariables, GetPackagesQuery, GetPackagesQueryVariables, RemoveTutorFromPackageMutation, RemoveTutorFromPackageMutationVariables } from '../gql/__generated__'
import { getApolloContext } from '@apollo/client'

type Props = {
  profileId: string
}

type State = {
  isLoaded: boolean,
  packages: GetPackagesQuery['getPackages']
}

class TutorPackagesContent extends React.Component<Props, State> {
  state = {
    packages: [],
    isLoaded: false
  }
  static contextType = getApolloContext()
  context!: React.ContextType<ReturnType<typeof getApolloContext>>

  componentDidMount = async () => {
    await this.getPackages()
    await this.setState({ isLoaded: true })
  }

  getPackages = async () => {
    const result = await this.context.client!.query<GetPackagesQuery, GetPackagesQueryVariables>({
      query: getPackagesQuery,
    })
    this.setState({ packages: result.data.getPackages })
  }

  assignTutorToPackage = async (packageId: string) => {
    await this.context.client!.mutate<AssignTutorToPackageMutation, AssignTutorToPackageMutationVariables>({
      mutation: assignTutorToPackageMutation,
      variables: {
        tutorId: this.props.profileId,
        packageId
      }
    })
  }

  removeTutorFromPackage = async (packageId: string) => {
    await this.context.client!.mutate<RemoveTutorFromPackageMutation, RemoveTutorFromPackageMutationVariables>({
      mutation: removeTutorFromPackageMutation,
      variables: {
        tutorId: this.props.profileId,
        packageId
      }
    })
  }

  handleAssignClick = async (packageId: string) => {
    try {
      await this.assignTutorToPackage(packageId)
      NotificationService.showSuccess('Package successfully assigned')
      await this.getPackages()
    } catch (err) {
      NotificationService.showError('Failed to assign tutor to package')
    }
  }

  handleRemoveClick = async (packageId: string) => {
    try {
      await this.removeTutorFromPackage(packageId)
      NotificationService.showSuccess('Tutor removed from package')
      await this.getPackages()
    } catch (err) {
      NotificationService.showError('Failed to remove tutor from package')
    }
  }

  render() {
    const columns = [
      {
        Header: 'id',
        accessor: 'id'
      },
      {
        Header: 'name',
        accessor: 'name'
      },
      {
        Header: 'Assigned',
        accessor: (item: any) => {
          const tutorIds = item.tutors.map((t: any) => t.tutorId)
          if (tutorIds.includes(this.props.profileId)) {
            return <Button onClick={() => this.handleRemoveClick(item.id)} variant='contained' color='primary'>Remove from package</Button>
          }

          return <Button onClick={() => this.handleAssignClick(item.id)} variant='contained' color='secondary'>Assign to package</Button>
        }
      }
    ]

    if (!this.state.isLoaded) {
      return null
    }

    return <GenericTable data={this.state.packages} columns={columns}/>
  }
}

export default TutorPackagesContent
