import cx from 'classnames'
import { v4 as uuid } from 'uuid'
import { FieldProps, getIn } from 'formik'
import React from 'react'
import Typography from '@material-ui/core/Typography'

import { FieldHelp, FieldMessage } from './Fields'
import { getUserImageUrl } from '../Utils'

type FormikUploadFieldProps = FieldProps & {
  help?: string
  label?: string
  id?: string
  type?: string
  checked?: boolean
}

export const FormikUploadField = ({
  field,
  form,
  help,
  label,
  ...inputProps
}: FormikUploadFieldProps) => {
  const id = inputProps.id || uuid()
  const type = inputProps.type || 'text'
  const touched = getIn(form.touched, field.name)
  const error = getIn(form.errors, field.name)

  // Checkbox property is passed to FieldInput component
  // because determining its values is not always same.
  // Some FieldInput(s) type=checkbox maps to bool values, some others to value in array or object
  if (type === 'checkbox') {
    inputProps.checked = !!getIn(form.values, field.name)
  }

  const inputElement = (
    <input
      className={cx({
        'form-control': !(type === 'radio' || type === 'checkbox'),
        'is-invalid':
          getIn(touched, field.name) && getIn(form.errors, field.name),
      })}
      id={id}
      type='file'
      // Uncontrolled
      value={undefined}
      onChange={(e: React.ChangeEvent<any>) => {
        form.setFieldValue(field.name, e.currentTarget.files)
      }}
      {...inputProps}
    />
  )

  return (
    <div className='form-group row'>
      {label && (
        <label className='col-sm-3 col-form-label' htmlFor={id}>
          {label}
        </label>
      )}
      <div className='col-sm-9'>
        {field.value ? (
          <img src={getUserImageUrl(field.value)} alt='' width='250' />
        ) : (
          <Typography color='error'>User has no image</Typography>
        )}
        {inputElement}
      </div>
      {touched && error && <FieldMessage>{error}</FieldMessage>}
      {help && <FieldHelp>{help}</FieldHelp>}
    </div>
  )
}
