import { useState } from 'react'
import Layout from '../layouts/Layout'
import { GenericTable } from '../components'
import Button from '@material-ui/core/Button'
import { GenericForm } from '../components'
import { formatDate } from '../Utils'
import { useApolloClient } from '@apollo/client'
import {
  createCoupon,
  createCouponBatch,
  getCoupons as getCouponQuery,
  getAllCoupons as getAllCouponQuery,
  getPackages,
  invalidateCoupon,
  createCouponInput,
  createCouponBatchInput,
  refreshCouponList,
} from './couponsPage/Utils'
import FilterForm from './couponsPage/FilterForm'
import CSVExport from './couponsPage/CSVExport'
import CreateCouponSchema, { initialValues, validationSchema } from './formSchemas/CreateCouponSchema'
import { initialValues as filterInitialValues } from './formSchemas/FilterCouponSchema'
import { CouponFiltersInput, GetAllCouponsQuery, GetCouponsQuery, GetPackagesQuery } from '../gql/__generated__'
import NotificationService, { MessageType } from '../services/NotificationService'
import { Typography } from '@material-ui/core'
import usePagination from '../utils/pagination'
import useFallibleEffect from '../utils/fallibleEffect'

type Props = {}

export default function CouponsPage(_props: Props) {
  const client = useApolloClient()

  const [isLoaded, setIsLoaded] = useState(false)
  const [coupons, setCoupons] = useState([] as GetCouponsQuery['getCoupons'])
  const [allCoupons, setAllCoupons] = useState([] as GetAllCouponsQuery['getAllCoupons'])
  const [packages, setPackages] = useState([] as GetPackagesQuery['getPackages'])
  const [filters, setFilters] = useState<CouponFiltersInput>(filterInitialValues)

  const { setCount, pagination } = usePagination({
    setIsLoaded,
    onUpdate: async (r, p) => { await getCoupons(r, p) },
    rowsPerPageOptions: [50, 100]
  })

  const getAllCoupons = async () => {
    const allCoupons = await getAllCouponQuery(client, filters)
    setCount(allCoupons.length)
    setAllCoupons(allCoupons)
  }
  const getCoupons = async (rowsPerPage = pagination.rowsPerPage, page = pagination.page) => {
    await getAllCoupons()
    const coupons = await getCouponQuery(client, filters, rowsPerPage, page * rowsPerPage)
    setCoupons(coupons)
  }
  useFallibleEffect(getCoupons, setIsLoaded, [filters])
  const loadData = async () => {
    const packages = await getPackages(client)
    setPackages(packages)
    await getCoupons()
  }
  useFallibleEffect(loadData, setIsLoaded, [])

  const handleSubmit = async (values: any) => {
    try {
      if (values.batchCouponCreation) {
        const couponBatch = createCouponBatchInput(values)
        await createCouponBatch(client, couponBatch)
        NotificationService.show(MessageType.success, 'Coupon batch successfully created')
      } else {
        const coupon = createCouponInput(values)
        await createCoupon(client, coupon)
        NotificationService.show(MessageType.success, 'Coupon successfully created')
      }
    } catch (err: any) {
      NotificationService.show(MessageType.error, err.message)
    }

    await getCoupons()
    return true
  }

  const handleRefresh = async () => {
    await refreshCouponList(client)
    window.location.reload()
  }

  const invalidateCouponCb = (couponId: string) => async () => {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm('Are you sure to invalidate this coupon?')) {
      return false
    }

    setIsLoaded(false)
    try {
      await invalidateCoupon(client, couponId)
      NotificationService.show(MessageType.success, 'Coupon successfully invalidated')
    } catch (err: any) {
      NotificationService.show(MessageType.error, err.message)
    }

    await getCoupons()
    setIsLoaded(true)
    return true
  }

  const columns = [
    {
      Header: 'Code',
      accessor: 'code',
    },
    {
      Header: 'Expiration date',
      accessor: (item: any) => {
        return formatDate(item.expiryDate)
      },
    },
    {
      Header: 'Multi acc. red.',
      accessor: (item: any) => {
        return item.multiAccount ? 'Yes' : 'No'
      },
    },
    {
      Header: 'Avail. uses',
      accessor: 'usesAvailable',
    },
    {
      Header: 'Uses per account',
      accessor: 'usesPerAccount',
    },
    {
      Header: 'Red. count',
      accessor: 'redemptionCount',
    },
    {
      Header: 'Note',
      accessor: 'note',
    },
    {
      Header: 'Package',
      accessor: 'packageId',
    },
    {
      Header: 'Created at',
      accessor: (item: any) => {
        return formatDate(item.createdAt)
      },
    },
    {
      Header: 'Canceled at',
      accessor: (item: any) => {
        return formatDate(item.canceledAt)
      },
    },
    {
      Header: 'Expired at',
      accessor: (item: any) => {
        return formatDate(item.expiredAt)
      },
    },
    {
      Header: 'Actions',
      accessor: (item: any) => {
        return (
          <>
            <Button
              color='primary'
              variant='contained'
              size='small'
              onClick={invalidateCouponCb(item.id)}
              disabled={item.canceledAt}
            >
              Invalidate
            </Button>
          </>
        )
      },
    },
  ]

  return (
    <Layout>
      <Typography variant='h4'>Coupons</Typography>

      {isLoaded && (
        <GenericForm
          getInitialValues={() => initialValues}
          schema={CreateCouponSchema(packages)}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        />
      )}

      <FilterForm setFilters={setFilters} />
      <p>{`* data jsou v cache, pro aktualizaci použij tlačítko Obnovit data`}</p>
      <Button
        type='button'
        variant='contained'
        color='primary'
        style={{ marginBottom: '2em', marginRight: '1em' }}
        onClick={handleRefresh}
      >
        Obnovit data
      </Button>

      <CSVExport data={allCoupons} />
      <GenericTable
        title='Coupons'
        data={coupons}
        columns={columns}
        pagination={pagination}
        isLoading={!isLoaded}
      />
    </Layout>
  )
}
