import React, { useState } from 'react'
import { Box, makeStyles, Paper, Typography, Button } from '@material-ui/core'
import Layout from '../layouts/Layout'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { formatDate, makeDateRange, makeEndUTCDate, makeStartUTCDate } from '../Utils'
import adminGetAllShiftsInRange from '../gql/queries/adminGetAllShiftsInRange'
import adminCancelShiftMutation from '../gql/mutations/adminCancelShift'
import { AdminCancelShiftMutation, AdminGetAllShiftsInRangeQuery, ShiftType } from '../gql/__generated__'
import CustomDialog from '../components/CustomDialog'
import NotificationService from '../services/NotificationService'
import { useMutation, useQuery } from '@apollo/client'

type Shift = AdminGetAllShiftsInRangeQuery['adminGetAllShiftsInRange'][number]

type ShiftEvent = {
  start: Date
  end: Date
  title: string
  type: ShiftType
  cancelled: boolean
  id: string
}

const useStyles = makeStyles({
  legendTitle: {
    marginTop: 10,
    marginBottom: 10,
  },
  legendLine: {
    padding: 3,
  },
  legendBox: {
    width: 20,
    heigth: 20,
    marginRight: 10,
  },
  modalButton: {
    marginTop: 10,
  },
})

moment.locale('cs', {
  week: {
    dow: 1,
    doy: 1,
  },
})

const localizer = momentLocalizer(moment)

type Props = {}

const ShiftsPage = (_props: Props) => {
  const classes = useStyles({})
  const defaultRange = makeDateRange()
  const [start, setStart] = useState(defaultRange.from)
  const [end, setEnd] = useState(defaultRange.to)
  const [open, setOpen] = useState(false)
  const [eventDetail, setEventDetail] = useState<ShiftEvent | null>(null)

  const { data, refetch } = useQuery<Shift[]>(adminGetAllShiftsInRange, {
    variables: {
      from: start,
      to: end,
    },
  })

  const [adminCancelShift] = useMutation<AdminCancelShiftMutation>(adminCancelShiftMutation)

  const mapData = (data: any): ShiftEvent[] => {
    return data.adminGetAllShiftsInRange.map((shift: Shift) => {
      return {
        id: shift.id,
        start: new Date(shift.start),
        end: new Date(shift.end),
        title: `${shift.tutor.user.firstName} ${shift.tutor.user.lastName}`,
        type: shift.type,
        cancelled: !!shift.cancelledAt,
      }
    })
  }

  const eventStyler = (event: ShiftEvent) => {
    let backgroundColor = '#3174ad'

    if (event.type === ShiftType.Online) {
      backgroundColor = 'green'
    }

    if (event.cancelled) {
      backgroundColor = 'red'
    }

    if (event.cancelled && event.type === ShiftType.Booking) {
      backgroundColor = '#a1002f'
    }

    const style = { backgroundColor, display: 'inline-flex' }
    return { style }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCancel = async () => {
    if (eventDetail) {
      try {
        await adminCancelShift({
          variables: {
            shiftId: eventDetail.id,
          },
        })
        setOpen(false)
        NotificationService.showSuccess('Shift successfully cancelled')
        await refetch({
          from: start,
          to: end,
        })
      } catch (err) {
        NotificationService.showError('Error cancelling shift')
      }
    }
  }

  return (
    <Layout>
      <Typography variant='h4'>Shifts</Typography>
      <Paper style={{ width: 1500, padding: 10, marginTop: 10 }}>
        {data && (
          <Calendar
            localizer={localizer}
            events={mapData(data)}
            startAccessor={'start'}
            endAccessor={'end'}
            defaultView='week'
            views={['week', 'day']}
            onSelectEvent={(event: ShiftEvent) => {
              setEventDetail(event)
              setOpen(true)
            }}
            eventPropGetter={eventStyler}
            min={new Date(2020, 1, 1, 7)}
            max={new Date(2020, 1, 1, 23)}
            formats={{ eventTimeRangeFormat: () => '' }}
            onRangeChange={(dates) => {
              if (Array.isArray(dates)) {
                setStart(makeStartUTCDate(dates[0]))
                setEnd(makeEndUTCDate(dates[dates.length - 1]))
              }
            }}
          />
        )}
        <Box>
          <Typography className={classes.legendTitle} variant='h5'>
            Legend
          </Typography>
          <Box display='flex' className={classes.legendLine}>
            <Box className={classes.legendBox} style={{ backgroundColor: 'green' }}></Box>
            <Typography variant='body1'>Type of shift is 'Online'</Typography>
          </Box>
          <Box display='flex' className={classes.legendLine}>
            <Box className={classes.legendBox} style={{ backgroundColor: '#3174ad' }}></Box>
            <Typography variant='body1'>Type of shift is 'Booking'</Typography>
          </Box>
          <Box display='flex' className={classes.legendLine}>
            <Box className={classes.legendBox} style={{ backgroundColor: 'red' }}></Box>
            <Typography variant='body1'>Shift is Online and cancelled</Typography>
          </Box>
          <Box display='flex' className={classes.legendLine}>
            <Box className={classes.legendBox} style={{ backgroundColor: '#a1002f' }}></Box>
            <Typography variant='body1'>Type of Booking and cancelled</Typography>
          </Box>
        </Box>
        {eventDetail && (
          <CustomDialog title='Shift detail' open={open} onClose={handleClose}>
            <Box padding={2}>
              <Typography variant='body1'>Tutor: {eventDetail.title}</Typography>
              <Typography variant='body1'>ID: {eventDetail.id}</Typography>
              <Typography variant='body1'>type: {eventDetail.type}</Typography>
              <Typography variant='body1'>start: {formatDate(eventDetail.start)}</Typography>
              <Typography variant='body1'>end: {formatDate(eventDetail.end)}</Typography>
              <Typography variant='body1'>cancelled: {eventDetail.cancelled.toString()}</Typography>
              {eventDetail.cancelled === false && (
                <Button className={classes.modalButton} variant='contained' color='secondary' onClick={handleCancel}>
                  Cancel Shift
                </Button>
              )}
            </Box>
          </CustomDialog>
        )}
      </Paper>
    </Layout>
  )
}

export default ShiftsPage
