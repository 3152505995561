import { GroupType } from '../../gql/__generated__'
import { FormSchema } from '../../components'

const initialValues = {
  name: '',
  type: '',
  groupInput: {
    billing: null,
    contact: null,
  },
}

const GroupSchema: FormSchema = {
  fieldsets: [
    {
      title: 'Basic info',
      size: 6,
      fields: [
        {
          label: 'Name',
          accessor: 'name',
        },
        {
          label: 'Type',
          accessor: 'type',
          fieldProps: {
            select: true,
            selectOptions: [
              { label: '(choose)', value: '' },
              ...Object.values(GroupType).map((type) => {
                return { value: type, label: type }
              }),
            ],
          },
        },
      ],
    },
    {
      title: 'Billing info',
      fields: [
        {
          label: 'Tax Id (DIC)',
          accessor: 'groupInput.billing.taxId',
        },
        {
          label: 'Company Id (ICO)',
          accessor: 'groupInput.billing.companyId',
        },
        {
          label: 'E-mail',
          accessor: 'groupInput.billing.email',
        },
        {
          label: 'Street',
          accessor: 'groupInput.billing.street',
        },
        {
          label: 'City',
          accessor: 'groupInput.billing.city',
        },
        {
          label: 'Country code',
          accessor: 'groupInput.billing.countryCode',
        },
        {
          label: 'ZIP',
          accessor: 'groupInput.billing.zip',
        },
      ],
    },
    {
      title: 'Contact',
      fields: [
        {
          label: 'E-mail',
          accessor: 'groupInput.contact.email',
        },
        {
          label: 'First name',
          accessor: 'groupInput.contact.firstName',
        },
        {
          label: 'Last name',
          accessor: 'groupInput.contact.lastName',
        },
        {
          label: 'Phone',
          accessor: 'groupInput.contact.phone',
        },
      ],
    },
  ],
}

export default GroupSchema

export { initialValues }
