import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import LogRocket from 'logrocket'
import { BrowserRouter } from 'react-router-dom'

import App from './App'

const { SENTRY_DSN, NODE_ENV, COMMIT_SHA, LOG_ROCKET_ID } = process.env

const sentryOptions = {
  dsn: SENTRY_DSN,
  environment: NODE_ENV,
  release: COMMIT_SHA,
  maxBreadcrumbs: NODE_ENV === 'development' ? 0 : 50,
  attachStacktrace: true,
  integrations: [],
  blacklistUrls: [
    /graph\.facebook\.com/i,
    /connect\.facebook\.net/i,
    /extensions\//i,
    /^chrome:\/\//i,
    /stats\.g\.doubleclick\.net/i,
    /www\.google-analytics\.com/i,
    /noembed\.com/i,
    /intercomcdn\.com/i,
    /paypal\.com/i,
    /lr-ingest\.io/i, // LogRocket recording service
  ],
}

if (LOG_ROCKET_ID) {
  LogRocket.init(LOG_ROCKET_ID)
}

Sentry.init(sentryOptions)

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root'),
)
