import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  StoreObject,
  defaultDataIdFromObject
} from '@apollo/client'
import { jwtDecode } from 'jwt-decode'
import Cookies from 'js-cookie'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { GraphQLError } from 'graphql'
import { KeyFieldsContext } from '@apollo/client/cache/inmemory/policies'

/**
 * This function builds up objects IDs for apollo cache.
 * 
 * It needs to perform special treatment of any entity which nests a Lesson because it might
 * include the `lessonUsage` field which is independent of the Lesson id and will be cached incorrectly
 * if not accounted for here.
 */
function dataIdFromObject(obj: Readonly<StoreObject>, ctx?: KeyFieldsContext) {
  if ('__ref' in obj) {
    return obj['__ref'] as string
  }
  
  let id = defaultDataIdFromObject(obj, ctx)

  switch (obj.__typename) {
    case 'Lesson': {
      if ('lessonUsage' in obj) {
        id = `${id}:${obj['lessonUsage']}`
      }
    } break;
    case 'Package': {
      if ('lessons' in obj) {
        const innerIds = (obj.lessons as any[]).map(l => dataIdFromObject(l)).join(',')
        id = `${id}[${innerIds}]`
      }
    } break;
  }

  return id;
}

const configureClient = (
  initialState: NormalizedCacheObject | undefined = undefined,
) => {

  const cache = new InMemoryCache({
    dataIdFromObject
  })

  const errorLink = onError(({ graphQLErrors, networkError, operation }: any) => {
    if (graphQLErrors) {
      const formattedGraphQLErrors = graphQLErrors.map(
        (error: GraphQLError) => {
          let message = ''
          if (error.message) {
            message = `[GraphQL error]: Message: ${error.message}`
          }
          if (error.locations) {
            message = `${message}, Operation: ${operation.operationName}
              , Locations: ${error.locations
              .map((location: any) => `${location.line}:${location.column}`)
              .join(', ')}, Path: ${error.path}`
          }
          return message
        },
      )

      const shouldRedirectToLogin = () => {
        const ck = Cookies.get('admin_token')
        if (!ck) {
          console.log('no admin_token')
          return true
        }
        const dck = ck && jwtDecode(ck) as any
        if (dck.exp < Math.floor((new Date()).getTime() / 1000)) {
          console.log('admin_token is expired', dck.iat, Math.floor((new Date()).getTime() / 1000))
          return true
        }
        const err = graphQLErrors.find((error: any) => {
          return (error.message.indexOf('Access denied!') !== -1) || [ 'TOKEN_EXPIRED', 'UNAUTHENTICATED' ].includes(error.extensions.code)
        })
        return err
      }

      if (shouldRedirectToLogin()) {
        console.log('redirecting to login')
        window.location.href = '/login'
      }

      if (
        typeof window !== 'undefined' &&
        process.env.NODE_ENV === 'production'
      ) {
        // @TODO Add Sentry logger
      } else {
        formattedGraphQLErrors.forEach((error: any) => {
          console.log('formatError')
          console.error(error, operation)
        })
      }
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`)
    }
  })

  const authLink = setContext((_, { headers }) => {
    const token = Cookies.get('admin_token')
    if (!token && _.operationName && !_.operationName.toLowerCase().includes('google')) {
      window.location.href = '/login'
      throw new Error('missing token')
    }
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  })

  const uploadLink = createUploadLink({
    uri: process.env.REACT_APP_GATEWAY_HOST,
    credentials: 'same-origin',
  })

  return new ApolloClient({
    link: ApolloLink.from([ errorLink, authLink, uploadLink ]),
    cache: initialState ? cache.restore(initialState) : cache,
    // https://www.apollographql.com/docs/react/features/developer-tooling.html#devtools
    connectToDevTools:
      typeof window === 'undefined' && process.env.NODE_ENV !== 'production',
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
    },
    ssrMode: typeof window === 'undefined',
  })
}

export default configureClient
