import React, { useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import Layout from '../layouts/Layout'
import { useParams } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import getNewHashMutation from '../gql/mutations/getNewHash'
import { useApolloClient } from '@apollo/client'
import { GetNewHashMutation, GetNewHashMutationVariables } from '../gql/__generated__'

type Props = {}

export default function GenerateTokenPage(_props: Props) {
  const identifier = useParams().identifier!
  const client = useApolloClient()

  const [newToken, setNewToken] = useState('')

  const handleClick = async () => {
    const result = await client.mutate<GetNewHashMutation, GetNewHashMutationVariables>({
      mutation: getNewHashMutation,
      variables: { identifier }
    })
    setNewToken(result.data!.getNewHash!)
  }

  return (
    <Layout>
      <Card>
        <CardContent>
          <Typography variant="h3" component="h3">Generate new token</Typography>
          <Typography variant="h5" component="h5">Identifier: {identifier}</Typography>
        </CardContent>
        <CardActions>
          <Button color='secondary' variant='contained' size="large" onClick={handleClick}>Generate</Button>
          {(newToken) && (<Typography variant="h5" component="h5">{`${process.env.REACT_APP_BASE_URL}confirm-email?token=${newToken}`}</Typography>)}
        </CardActions>
      </Card>
    </Layout>
  )
}
