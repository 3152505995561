import React from 'react'
import Layout from '../layouts/Layout'
import UsersStats from '../components/UsersStats'
import CallsStats from '../components/CallsStats'
import InviteStats from '../components/InviteStats'
import PaymentStats from '../components/PaymentStats'

type Props = {}

class DashboardPage extends React.Component<Props> {
  render() {
    return (
      <Layout>
        <UsersStats />
        <CallsStats />
        <InviteStats />
        <PaymentStats />
      </Layout>
    )
  }
}

export default DashboardPage
