import React from 'react'
import { GenericForm } from '../../components/GenericForm'
import FilterCouponSchema, { initialValues, validationSchema } from '../formSchemas/FilterCouponSchema'
import { CouponFiltersInput } from '../../gql/__generated__'

type Props = {
  setFilters: (filters: CouponFiltersInput) => void
}

const FilterForm = ({ setFilters }: Props) => {
  const getInitialValues = () => initialValues

  const handleSubmit = async (values: CouponFiltersInput) => {
    setFilters(values)
  }

  return (
    <GenericForm
      getInitialValues={getInitialValues}
      schema={FilterCouponSchema()}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    />
  )
}

export default FilterForm
