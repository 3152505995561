import { FormSchema } from '../../components'
import { FormikDateTimePicker } from '../../components/FormikDateTimePicker'
import * as Yup from 'yup'

export type Values = {
  batchCouponCreation: boolean
  numberOfCoupons?: number
  lengthOfCode?: number
  code?: string
  expiryDate: Date
  multiAccount: boolean
  usesAvailable: number
  usesPerAccount: number
  note: string
  createdBy: string
  packageId: string
}

export const initialValues = {
  batchCouponCreation: false,
  numberOfCoupons: '',
  lengthOfCode: '',
  code: '',
  expiryDate: null,
  multiAccount: false,
  usesAvailable: 0,
  usesPerAccount: 1,
  note: '',
  createdBy: '',
  packageId: '',
}

export const validationSchema = Yup.object().shape({
  lengthOfCode: Yup.number().min(6, 'Code must contain 6-8 characters').max(8, 'Code must contain 6-8 characters'),
  code: Yup.string().matches(/^[A-Z0-9]{6,8}$/, 'Code must contain 6-8 uppercase characters'),
})

const booleanFieldsProps = {
  select: true,
  selectOptions: [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ],
}

const CreateCouponSchema = (packages: any[]): FormSchema => ({
  fieldsets: [
    {
      title: 'Create coupon',
      fields: [
        {
          label: 'Batch coupon creation',
          accessor: 'batchCouponCreation',
          fieldProps: booleanFieldsProps,
        },
        {
          label: 'Number of coupons',
          accessor: 'numberOfCoupons',
          fieldProps: {
            required: true,
          },
          hideWhen: {
            accessor: 'batchCouponCreation',
            value: false,
          },
        },
        {
          label: 'Length of code',
          accessor: 'lengthOfCode',
          fieldProps: {
            required: true,
          },
          hideWhen: {
            accessor: 'batchCouponCreation',
            value: false,
          },
        },
        {
          label: 'Code',
          accessor: 'code',
          fieldProps: {
            required: true,
          },
          hideWhen: {
            accessor: 'batchCouponCreation',
            value: true,
          },
        },
        {
          label: 'Expiration date (in UTC)',
          accessor: 'expiryDate',
          component: FormikDateTimePicker,
          fieldProps: {
            disableFuture: false,
            disablePast: true,
          },
        },
        {
          label: 'Can by redeemed by multiple accounts',
          accessor: 'multiAccount',
          fieldProps: booleanFieldsProps,
        },
        {
          label: 'Available uses',
          accessor: 'usesAvailable',
        },
        {
          label: 'Available uses per account',
          accessor: 'usesPerAccount',
        },
        {
          label: 'Note',
          accessor: 'note',
        },
        {
          label: 'Package',
          accessor: 'packageId',
          fieldProps: {
            required: true,
            select: true,
            selectOptions: packages.map((item) => ({ label: item.name, value: item.id })),
          },
        },
      ],
    },
  ],
})

export default CreateCouponSchema
