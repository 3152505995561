import gql from 'graphql-tag'

export default gql`
  mutation createSystemMessage($message: MessageInput!) {
    createSystemMessage(message: $message) {
      id
      content
    }
  }
`
