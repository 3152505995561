import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../layouts/Layout'
import { GenericForm, GenericTable } from '../components'
import CircularProgress from '@material-ui/core/CircularProgress'
import ProfileDetail from '../components/ProfileDetail'
import NotificationService from '../services/NotificationService'
import getProfileWithContacts from '../gql/queries/getProfileWithContacts'
import AddContactSchema, { initialValues } from './formSchemas/AddContactSchema'
import adminCreateContact from '../gql/mutations/adminCreateContact'
import UserPagesNavigation from '../components/UserPagesNavigation'
import { UserPagesTitles } from '../enums'
import Typography from '@material-ui/core/Typography'
import { AdminCreateContactMutation, AdminCreateContactMutationVariables, ContactType, GetProfileWithContactsQuery, GetProfileWithContactsQueryVariables, VerifyEmailContactMutation, VerifyEmailContactMutationVariables } from '../gql/__generated__'
import Button from '@material-ui/core/Button'
import { formatDate } from '../Utils'
import verifyEmailContact from '../gql/mutations/verifyEmailContact'
import { useApolloClient } from '@apollo/client'
import { wrapFallible } from '../utils/fallibleEffect'

type Props = {}

export default function ContactsPage(_props: Props) {
  const profileId = useParams().profileId!
  const client = useApolloClient()

  const [isLoaded, setIsLoaded] = useState(false)
  const [profile, setProfile] = useState<GetProfileWithContactsQuery['getProfileFixed'] | undefined>(undefined)

  const handleSubmit = async (values: any) => {
    await client.mutate<AdminCreateContactMutation, AdminCreateContactMutationVariables>({
      mutation: adminCreateContact,
      variables: { contactInput: { ...values, userId: profile!.user.id } }
    })

    await getProfile()
  }
  
  const handleClick = async (email: string) => {
    try {
      await client.mutate<VerifyEmailContactMutation, VerifyEmailContactMutationVariables>({
        mutation: verifyEmailContact,
        variables: {
          email,
          userId: profile!.user.id
        }
      })
      window.location.reload()
    } catch (err) {
      NotificationService.showError('Cannot verify contact')
    }
  }
  
  const getProfile = wrapFallible(async () => {
    const result = await client.query<GetProfileWithContactsQuery, GetProfileWithContactsQueryVariables>({
      query: getProfileWithContacts,
      variables: { id: profileId }
    })
    setProfile(result.data.getProfileFixed)
  }, setIsLoaded)
  useEffect(() => { getProfile() }, [])

  const columns = [
    {
      Header: 'type',
      accessor: '__typename'
    },
    {
      Header: 'contact',
      accessor: ({ __typename, ...rest }: any) => JSON.stringify(rest)
    },
    {
      Header: 'verified',
      accessor: (item: any) => {
        if (item.__typename === ContactType.Email && !item.verifiedAt) {
          return <Button onClick={() => handleClick(item.email)} color='primary' variant='contained' size='small'>Verify</Button>
        }

        return formatDate(item.verifiedAt)
      }
    }
  ]

  if (!isLoaded) {
    return (
      <Layout>
        <CircularProgress size={20} thickness={5} />
      </Layout>
    )
  }

  if (profile!.user.contacts) {
    return (
      <Layout>
        <Typography variant="h4">Profile detail - contacts</Typography>
        <UserPagesNavigation profileId={profileId} title={UserPagesTitles.Contacts}/>
        <div>
          <GenericForm
            getInitialValues={() => initialValues}
            schema={AddContactSchema}
            onSubmit={handleSubmit}
          />
          <ProfileDetail user={profile!.user} profileId={profileId} />
          <GenericTable data={profile!.user.contacts} columns={columns}/>
        </div>
      </Layout>
    )
  }

  return <></>
}
