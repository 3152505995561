import { useEffect } from "react"
import NotificationService from "../services/NotificationService"

export default function useFallibleEffect(effect: () => Promise<void>, setIsLoaded: (isLoaded: boolean) => void, deps?: readonly any[]) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { wrapFallible(effect, setIsLoaded, deps)() }, deps)
}

export function wrapFallible<T extends unknown[]>(effect: (...args: T) => Promise<void>, setIsLoaded: (isLoaded: boolean) => void, deps?: readonly any[]) {
  return async (...args: T) => {
    setIsLoaded(false)
    if ((deps ?? []).every(v => v !== undefined && v !== null)) {
      try {
        const r = await effect(...args)
        setIsLoaded(true)
        return r
      } catch (err: any) {
        NotificationService.showError(err)
        throw err
      }
    }
  }
}
