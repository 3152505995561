const removeTypenameFromData = (value: any): any => {
  if (value === null || value === undefined) {
    return value;
  } else if (Array.isArray(value)) {
    return value.map(v => removeTypenameFromData(v));
  } else if (typeof value === 'object') {
    const newObj: any = {};
    Object.entries(value).forEach(([key, v]) => {
      if (key !== '__typename') {
        newObj[key] = removeTypenameFromData(v);
      }
    });
    return newObj;
  }
  return value;
};

const errorToMessage = (error: any): string => {
  return String(error);
};

export { errorToMessage, removeTypenameFromData };
