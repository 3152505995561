import gql from 'graphql-tag'

export default gql`
  query statsCountUsers($dateRangeInput: DateRangeInput!) {
    statsCountUsers(dateRangeInput: $dateRangeInput) {
      date
      count
    }
  }
`
