import { FormSchema } from '../../components'

const initialValues = {
  content: ''
}

const CreateSystemMessageSchema: FormSchema = {
  fieldsets: [
    {
      title: 'Send system message',
      fields: [
        {
          label: 'Send system message',
          accessor: 'content',
          fieldProps: {
            multiline: true
          }
        }
      ]
    }
  ]
}

export default CreateSystemMessageSchema

export { initialValues }
