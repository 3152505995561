import Layout from '../layouts/Layout'
import { Typography } from '@material-ui/core'
import { GenericForm, RouterLink } from '../components'
import GroupSchema, { initialValues } from './formSchemas/GroupSchema'
import adminCreateGroupMutation from '../gql/mutations/adminCreateGroup'
import Button from '@material-ui/core/Button'
import NotificationService, { MessageType } from '../services/NotificationService'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router'
import { AdminCreateGroupMutation } from '../gql/__generated__'

type Props = {}

const GroupCreatePage = (_props: Props) => {
  const navigate = useNavigate()
  const [adminCreateGroup] = useMutation<AdminCreateGroupMutation>(adminCreateGroupMutation)
  const getInitialValues = () => initialValues
  const handleSubmit = async (values: any) => {
    try {
      await adminCreateGroup({
        variables: values
      })
      NotificationService.show(MessageType.success, 'Saved successfully')
      navigate('/groups')
    } catch (err: any) {
      NotificationService.showError(err.message)
    }

  }
  return (
    <Layout>
      <Typography variant="h4">
        Create Group
        <RouterLink to='/groups'>
          <Button
            color='secondary'
            style={{ float: 'right' }}
            variant='contained'
          >
            Back to groups
          </Button>
        </RouterLink>
      </Typography>
      <GenericForm
        getInitialValues={getInitialValues}
        schema={GroupSchema}
        onSubmit={handleSubmit}
      />
    </Layout>
  )
}

export default GroupCreatePage
