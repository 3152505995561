import defaultTo from 'lodash/defaultTo'
import React, { ReactNode } from 'react'
import { StyleRulesCallback, withStyles, WithStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Link from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TablePagination, { TablePaginationProps } from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import get from 'lodash/get'

import { RouterLink } from '../components'
import { Typography } from '@material-ui/core'

const styles: StyleRulesCallback<any, any> = (theme) => ({
  h3: {
    padding: '20px 20px 0px 20px',
  },
  cell: {
    maxWidth: 500,
  },
})

type TableProps = WithStyles<typeof styles> & {
  title?: string
  subtitle?: string
  data: Array<Object>
  pagination?: TablePaginationProps
  columns: Array<{
    Header: string
    accessor: ((item: Object) => string | ReactNode) | string
    link?: (item: Object) => string
  }>
  isLoading?: boolean
}

class GenericTableImpl extends React.Component<TableProps> {
  renderBody() {
    const { columns, data, classes, isLoading } = this.props

    if (isLoading) {
      return (
        <TableBody>
          <TableRow>
            {columns.map((_, index) => (
              <TableCell key={index}>
                {index === Math.floor(columns.length / 2) && <CircularProgress size={20} thickness={5} />}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      )
    }

    return (
      <TableBody>
        {!data && <span>Error when loading data..</span>}
        {data &&
          data.map((item: Object, i) => (
            <TableRow key={i}>
              {columns.map((column, j) => {
                const content = (
                  <>
                    {typeof column.accessor === 'string' && defaultTo(get(item, column.accessor), '–')}
                    {typeof column.accessor === 'function' && defaultTo(column.accessor(item), '–')}
                  </>
                )

                return (
                  <TableCell classes={{ root: classes.cell }} key={j}>
                    {column.link ? (
                      <RouterLink passHref to={column.link(item)}>
                        <Link>{content}</Link>
                      </RouterLink>
                    ) : (
                      content
                    )}
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
      </TableBody>
    )
  }

  renderHeader() {
    const { columns } = this.props

    return (
      <TableHead>
        <TableRow>
          {columns.map((column, i) => (
            <TableCell key={i}>{column.Header}</TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }

  render() {
    const { classes, pagination } = this.props
    return (
      <Paper>
        {this.props.title && (
          <Typography classes={{ h5: classes.h3 }} variant='h5'>
            {this.props.title}
          </Typography>
        )}
        {this.props.subtitle && (
          <Typography classes={{ h5: classes.h3 }} variant='h5'>
            {this.props.subtitle}
          </Typography>
        )}
        <Table>
          {this.renderHeader()}
          {this.renderBody()}
        </Table>
        {pagination && (
          <TablePagination
            rowsPerPageOptions={pagination.rowsPerPageOptions}
            component='div'
            count={pagination.count}
            rowsPerPage={pagination.rowsPerPage}
            page={pagination.page}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={pagination.onPageChange}
            onRowsPerPageChange={pagination.onRowsPerPageChange}
          />
        )}
      </Paper>
    )
  }
}

export const GenericTable = withStyles(styles)(GenericTableImpl)
