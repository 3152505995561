import gql from 'graphql-tag'
import TutorListFragment from '../fragments/tutorListFragment'

export default gql`
  query getTutors($first: Int = 10, $offset: Int = 0) {
    getTutors(pageInfo: { first: $first, offset: $offset }) {
      ...TutorListFragment
    }
  }
  ${TutorListFragment}
`
