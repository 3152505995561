import { useState } from 'react'
import { useParams } from 'react-router'
import { CardActions, WithStyles, withStyles } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import UserPagesNavigation from '../components/UserPagesNavigation'
import { UserPagesTitles } from '../enums'
import Layout from '../layouts/Layout'
import NotificationService from '../services/NotificationService'
import Button from '@material-ui/core/Button'
import banUserMutation from '../gql/mutations/banUser'
import getProfileQuery from '../gql/queries/getProfileWithBan'
import { GetProfileWithBanQuery, GetProfileWithBanQueryVariables } from "../gql/__generated__";
import { useApolloClient } from '@apollo/client'
import useFallibleEffect from '../utils/fallibleEffect'

const styles = {
  card: {
    padding: 16
  }
}

type Props = WithStyles

function BanPage({ classes }: Props) {
  const client = useApolloClient()
  const profileId = useParams().profileId!

  const [isLoaded, setIsLoaded] = useState(false)
  const [invalid, setInvalid] = useState(false)
  const [profile, setProfile] = useState<GetProfileWithBanQuery['getProfileFixed'] | undefined>(undefined)

  const handleBan = async () => {
    try {
      await client.mutate({
        mutation: banUserMutation,
        variables: {
          banInput: {
            userId: profile!.user.id,
            comment: "Banned by admin"
          }
        }
      })
      window.location.reload()
    } catch (err) {
      NotificationService.showError('Error banning user')
    }
  }
  useFallibleEffect(async () => {
    const result = await client.query<GetProfileWithBanQuery, GetProfileWithBanQueryVariables>({
      query: getProfileQuery,
      variables: { id: profileId }
    })
    const { data: { getProfileFixed } } = result

    setProfile(getProfileFixed)
    setInvalid(getProfileFixed.user.ban?.id != null)
  }, setIsLoaded, [])

  if (!isLoaded) {
    return (
      <Layout>
        <CircularProgress size={20} thickness={5} />
      </Layout>
    )
  }

  return (
    <Layout>
      <Typography variant="h4">Ban user</Typography>
      <UserPagesNavigation profileId={profileId} title={UserPagesTitles.Ban}/>
      <Card>
        <CardContent>
          <Typography variant="h3">Ban User</Typography>
          <Typography variant="body1">User won't be able to log-in or perform any actions.</Typography>
        </CardContent>
        <CardActions classes={{ root: classes.card }}>
          {invalid && `Already banned`}
          {!invalid && <Button onClick={handleBan} color='primary' variant='contained'>Ban</Button>}
        </CardActions>
      </Card>
    </Layout>
  )
}

export default withStyles(styles)(BanPage)
