import TutorUpdateFragment from '../fragments/tutorUpdateFragment'
import gql from 'graphql-tag'

export default gql`
  mutation TutorCreateMutation($tutor: AdminTutorInput!, $image: Upload) {
    adminCreateTutor(tutor: $tutor, image: $image) {
      ...TutorUpdateFragment
    }
  }
  ${TutorUpdateFragment}
`
