const palette = {
  primary: {
    light: '#FFFAEF',
    main: '#FFB400',
    dark: '#D29505',
    contrastText: '#000',
  },
  secondary: {
    light: '#ffdaad',
    main: '#FFB400',
    dark: '#D29505',
    contrastText: '#000',
  }
}

export default palette
