import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { ProfileUser } from '../types'

type Props = WithStyles<typeof styles> & {
  user: ProfileUser
  wallets?: any
  profileId?: string
}

const styles = {
  card: {
    marginBottom: 20,
  },
}

const ProfileDetail = (props: Props) => {
  const { classes, user, wallets } = props
  return (
    <Card classes={{ root: classes.card }}>
      <CardContent>
        <Grid container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
        spacing={2}>
          <Grid item xs={4}>Identifier: <b>{user.credentials && user.credentials.length && user.credentials[0].identifier}</b></Grid>
          <Grid item xs={4}>Name: <b>{user.firstName} {user.lastName}</b></Grid>
          <Grid item xs={4}>UserId: <b>{user.id}</b></Grid>
          <Grid item xs={4}>ProfileId: <b>{props.profileId}</b></Grid>
            <Grid item xs={4}>
              {wallets && wallets.map((wallet: any) => (
                <>
                {wallet.type}: <b>{wallet.balance} $</b> &nbsp;
                </>
                ))}
            </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default withStyles(styles)(ProfileDetail)
