import { FormikUploadField, FormSchema } from '../../components'
import { timeZones, countries } from '../../api/Data'
import { TutorUpdateQueryQuery } from '../../gql/__generated__'

type Tutor = TutorUpdateQueryQuery['adminGetTutor']
type NormalizedTutor = Omit<Tutor, 'intros'> & { introCs: NormalizedIntro, introEn: NormalizedIntro }
type NormalizedIntro = {
  enabled: boolean
  motto: string
  introduction: string
}

const initialValues = {
  id: '',
  intros: [{
    language: 'cs',
    motto: '',
    introduction: ''
  }],
  rating: 5.0,
  topicIds: [],
  user: {
    id: '',
    firstName: '',
    lastName: '',
    countryCode: 'cze',
    timezone: 'Europe/Belgrade',
    image: '',
    gender: 'Female',
    credential: {
      identifier: '',
      hash: '',
    },
  }
}

const normalize = (tutor: Tutor): NormalizedTutor => {
  // We don't read hash in query. It's not safe.
  // But form needs hash in initial values
  if ((tutor?.user as any)?.credential) {
    (tutor.user as any).credential.hash = ''
  }

  const introCs: NormalizedIntro = { enabled: false, motto: '', introduction: '' }
  const introEn: NormalizedIntro = { enabled: false, motto: '', introduction: '' }
  for (const intro of tutor.intros) {
    let i
    switch (intro.language) {
      case 'cs':
        i = introCs
        break
      case 'en':
        i = introEn
        break
      default:
        continue
    }

    i.enabled = true
    i.motto = intro.motto ?? ''
    i.introduction = intro.introduction
  }

  return {
    ...tutor,
    introCs,
    introEn
  }
}
const denormalize = (formTutor: NormalizedTutor): Tutor => {
  const { introCs, introEn } = formTutor

  const intros: Tutor['intros'] = []
  if (introCs.enabled) {
    intros.push({ language: 'cs', motto: introCs.motto, introduction: introCs.introduction } satisfies Omit<Tutor['intros'][number], '__typename'> as Tutor['intros'][number])
  }
  if (introEn.enabled) {
    intros.push({ language: 'en', motto: introEn.motto, introduction: introEn.introduction } satisfies Omit<Tutor['intros'][number], '__typename'> as Tutor['intros'][number])
  }

  const result = {
    ...formTutor,
    intros,
  }
  delete (result as any).introCs
  delete (result as any).introEn
  return result
}

const tutorFormSchema = (topics: { label: string, value: string }[]): FormSchema => {
  return {
    normalize,
    denormalize,
    fieldsets: [
      {
        title: 'User',
        fields: [
          {
            label: 'First name',
            accessor: 'user.firstName',
          },
          {
            label: 'Last name',
            accessor: 'user.lastName',
          },
          {
            label: 'Country',
            accessor: 'user.countryCode',
            fieldProps: {
              select: true,
              selectOptions: [
                { label: '(choose)', value: '' },
                ...countries.map((country) => {
                  return { value: country['alpha-3'].toLowerCase(), label: country.name }
                }),
              ],
              default: 'cze',
            },
          },
          {
            label: 'Gender',
            accessor: 'user.gender',
            fieldProps: {
              select: true,
              selectOptions: [
                { label: '(choose)', value: '' },
                { label: 'Male', value: 'Male' },
                { label: 'Female', value: 'Female' },
              ],
            },
          },
          {
            label: 'Timezone',
            accessor: 'user.timezone',
            fieldProps: {
              select: true,
              selectOptions: [
                { label: '(choose)', value: '' },
                ...timeZones.map(({ text, utc }) => {
                  return { value: utc[0], label: text }
                }),
              ],
            },
          },
          {
            label: 'Image',
            accessor: 'user.image',
            component: FormikUploadField,
            fieldProps: {},
          },
          {
            label: 'Topics',
            accessor: 'topicIds',
            fieldProps: {
              select: true,
              multiple: true,
              selectOptions: topics
            }
          }
        ],
      },
      {
        title: 'Therapist profile',
        size: 6,
        fields: [
          {
            label: 'Speaks czech',
            accessor: 'introCs.enabled',
            fieldProps: {
              type: 'checkbox'
            }
          },
          {
            label: 'Motto czech',
            accessor: 'introCs.motto',
            fieldProps: {
              multiline: true,
              rows: 2,
            },
            hideWhen: {
              accessor: 'introCs.enabled',
              value: false
            }
          },
          {
            label: 'Introduction czech',
            accessor: 'introCs.introduction',
            fieldProps: {
              placeholder: 'Type something here...',
              multiline: true,
              rows: 30,
            },
            hideWhen: {
              accessor: 'introCs.enabled',
              value: false
            }
          },
          {
            label: 'Speaks english',
            accessor: 'introEn.enabled',
            fieldProps: {
              type: 'checkbox'
            }
          },
          {
            label: 'Motto english',
            accessor: 'introEn.motto',
            fieldProps: {
              multiline: true,
              rows: 2,
            },
            hideWhen: {
              accessor: 'introEn.enabled',
              value: false
            }
          },
          {
            label: 'Introduction english',
            accessor: 'introEn.introduction',
            fieldProps: {
              placeholder: 'Type something here...',
              multiline: true,
              rows: 30,
            },
            hideWhen: {
              accessor: 'introEn.enabled',
              value: false
            }
          },
          {
            label: 'Rating',
            accessor: 'rating',
            fieldProps: {
              min: 0,
              step: 0.1,
              type: 'number',
            },
          },
        ],
      },
      {
        title: 'Credential',
        fields: [
          {
            label: 'E-mail',
            accessor: 'user.credentials[0].identifier',
          },
          {
            label: 'Password',
            accessor: 'user.credentials[0].hash',
            fieldProps: {
              type: 'password',
            },
          },
        ],
      },
    ]
  }
}

export { tutorFormSchema, initialValues }
