import { FormSchema } from '../../components'

const initialValues = {
  package: ''
}

const makePurchasePackageSchema = (packages: { id: string, name: string }[]): FormSchema => {
  return {
    fieldsets: [
      {
        title: 'Assign package',
        fields: [
          {
            label: 'Package',
            accessor: 'packageId',
            fieldProps: {
              select: true,
              selectOptions: packages.map(p => ({ key: p.id, label: p.name, value: p.id }))
            }
          }
        ]
      }
    ]
  }
}

export default makePurchasePackageSchema

export { initialValues }
