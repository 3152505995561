import gql from 'graphql-tag'

export default gql`
  query getProfileWithBan($id: String!) {
    getProfileFixed(id: $id) {
      ... on Tutor {
        id
        user {
          id
          firstName
          lastName
          credentials {
            identifier
          }
          ban {
            id
          }
        }
      }
      ... on Student {
        id
        user {
          id
          firstName
          lastName
          credentials {
            identifier
          }
          ban {
            id
          }
        }
      }
    }
  }
`
