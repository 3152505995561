import { useState } from "react";

export type UsePaginationProps = {
  /**
   * This callback will be called every time `rowsPerPage` or `page` change.
   * It is modelled "synchronously" from the point of view of react so that we can call `setIsLoaded` afterwards from this hook.
   * 
   * TODO: is this sane from the point of view of react?
   */
  onUpdate: (rowsPerPage: number, page: number) => Promise<void>
  setIsLoaded: (isLoaded: boolean) => void,
  count?: number,
  rowsPerPageOptions?: number[]
}

export default function usePagination({ onUpdate, setIsLoaded, rowsPerPageOptions, count }: UsePaginationProps) {
  const perPageOptions = rowsPerPageOptions ?? [15, 30, 50]
  const [pagination, setPagination] = useState({
    count: count ?? -1,
    page: 0,
    rowsPerPage: perPageOptions[0],
    rowsPerPageOptions: perPageOptions
  })

  const setRowsPerPage = (rowsPerPage: number) => {
    setPagination(p => {
      // calculate equivalent page with the new rowsPerPage value
      const currentRowOffset = p.page * p.rowsPerPage
      const newPage = Math.floor(currentRowOffset / rowsPerPage)

      return { ...p, rowsPerPage, page: newPage }
    })
  }

  const setPage = (page: number) => {
    setPagination(p => ({ ...p, page }))
  }
  
  const setCount = (count: number) => {
    // TODO: what if count > page * rows
    setPagination(p => ({ ...p, count }))
  }

  const addToCount = (delta: number) => {
    setPagination(p => {
      let newCount = p.rowsPerPage * p.page + delta

      // if we are exactly at the boundary we add 1 so that we get a chance to load another page
      const pages = newCount / p.rowsPerPage 
      if (pages === Math.trunc(pages)) {
        newCount += 1
      }

      return { ...p, count: newCount }
    })
  }

  const onRowsPerPageChange = async (event: any) => {
    const rowsPerPage = +event.target.value
    setIsLoaded(false)

    setRowsPerPage(rowsPerPage)
    await onUpdate(rowsPerPage, pagination.page)

    setIsLoaded(true)
  }

  const onPageChange = async (_events: any, page: number) => {
    setIsLoaded(false)

    setPage(page)
    await onUpdate(pagination.rowsPerPage, page)

    setIsLoaded(true)
  }

  return {
    setRowsPerPage,
    setPage,
    setCount,
    addToCount,
    pagination: {
      ...pagination,
      onRowsPerPageChange,
      onPageChange
    }
  }
}