import cx from 'classnames'
import { v4 as uuid } from 'uuid'
import React, { ReactNode } from 'react'
import { getIn, FieldProps } from 'formik'

interface MessageProps {
  children: ReactNode
}

export const FormMessage = ({ children }: MessageProps) => (
  <div className='alert alert-danger'>
    <span className='badge badge-danger text-uppercase align-middle'>
      Error
    </span>{' '}
    <span className='align-middle'>{children}</span>
  </div>
)

export const FieldMessage = ({ children }: MessageProps) => (
  <div className='invalid-feedback d-block'>
    <span className='badge badge-danger text-uppercase align-middle'>
      Fehler
    </span>{' '}
    <span className='align-middle'>{children}</span>
  </div>
)

type FieldInputProps = FieldProps & {
  id?: string
  type?: string
  checked?: boolean
  children?: ReactNode
}

export const FieldInput = ({
  field: { value, onChange, ...fieldProps },
  form: { touched, errors, setFieldValue },
  children,
  type,
  ...props
}: FieldInputProps) => {
  const Tag = type === 'select' || type === 'textarea' ? type : 'input'
  const inputType = Tag === 'input' ? type : undefined
  const inputValue = type === 'file' ? undefined : value

  // Checked property have to be passed from outside
  // because determining its values is not always same.
  // Some FieldInput(s) type=checkbox maps to bool values, some others to value in array or object
  if (type === 'checkbox' && props.checked === undefined) {
    throw new Error(
      'You have to always pass checked prop to <FieldInput type="checkbox" />',
    )
  }

  if (type === 'file') {
    onChange = (e: React.ChangeEvent<any>) => {
      setFieldValue(fieldProps.name, e.currentTarget.files)
    }
  }

  return (
    <Tag
      className={cx({
        'form-check-input': type === 'radio' || type === 'checkbox',
        'form-control': !(type === 'radio' || type === 'checkbox'),
        'is-invalid':
          getIn(touched, fieldProps.name) && getIn(errors, fieldProps.name),
      })}
      type={inputType}
      value={inputValue}
      onChange={onChange}
      {...fieldProps}
      {...props}
    >
      {children}
    </Tag>
  )
}

type FieldGroupProps = FieldProps & {
  help?: string
  label?: string
  id?: string
  type?: string
  checked?: boolean
}

export const FieldGroup = ({
  field,
  form,
  help,
  label,
  ...inputProps
}: FieldGroupProps) => {
  const id = inputProps.id || uuid()
  const type = inputProps.type || 'text'
  const touched = getIn(form.touched, field.name)
  const error = getIn(form.errors, field.name)

  // Checkbox property is passed to FieldInput component
  // because determining its values is not always same.
  // Some FieldInput(s) type=checkbox maps to bool values, some others to value in array or object
  if (type === 'checkbox') {
    inputProps.checked = !!getIn(form.values, field.name)
  }

  const inputElement = (
    <FieldInput field={field} form={form} id={id} type={type} {...inputProps} />
  )

  switch (type) {
    case 'radio':
    case 'checkbox':
      return (
        <div className='form-group form-check'>
          {inputElement}
          {label && (
            <label className='form-check-label' htmlFor={id}>
              {label}
            </label>
          )}
          {touched && error && <FieldMessage>{error}</FieldMessage>}
        </div>
      )
    case 'hidden':
      return inputElement
    default:
      return (
        <div className='form-group row'>
          {label && (
            <label className='col-sm-3 col-form-label' htmlFor={id}>
              {label}
            </label>
          )}
          <div className='col-sm-9'>{inputElement}</div>
          {touched && error && <FieldMessage>{error}</FieldMessage>}
          {help && <FieldHelp>{help}</FieldHelp>}
        </div>
      )
  }
}

interface HelpProps {
  children: ReactNode
}

export const FieldHelp = ({ children }: HelpProps) => (
  <small className='form-text text-muted'>{children}</small>
)
