import { useLocation } from 'react-router-dom'

import { useEffect, useState } from 'react'
import cookie from 'cookie'
import generateGoogleAuthUrl from '../gql/queries/generateGoogleAuthUrl'
import googleLogin from '../gql/queries/googleLogin'
import queryString from 'query-string'
import { useApolloClient } from '@apollo/client'

const isLocal = process.env.REACT_APP_LOCAL === 'true' ? true : null

type Props = {}

export default function OAuthGooglePage(_props: Props) {
  const params = queryString.parse(useLocation().search)
  const client = useApolloClient()
  
  const [error, setError] = useState('')

  useEffect(() => {
    if (params.password) {
      client.query({
        query: googleLogin,
        variables: {
          code: params.password,
          isLocal: true
        }
      }).then(response => {
        document.cookie = cookie.serialize('admin_token', response.data.googleLogin.token, { path: '/', expires: new Date(response.data.googleLogin.expiresAt) })
        window.location.href = '/'
      }).catch(err => setError(err.message))

      return
    }

    if (params.code) {
      client.query({
        query: googleLogin,
        variables: {
          code: params.code,
          isLocal
        }
      }).then(response => {
        if (response.errors) {
          let errorString = ''
          response.errors.map(error => errorString += error.message)
          setError(errorString)
        }

        if (response.data.googleLogin) {
          document.cookie = cookie.serialize('admin_token', response.data.googleLogin.token, { path: '/', expires: new Date(response.data.googleLogin.expiresAt) })
          window.location.href = '/'
        }
      }).catch(err => setError(err.message))
    } else {
      client.query({
        query: generateGoogleAuthUrl,
        variables: {
          isLocal
        }
      }).then(response => {
        window.location.href = response.data.generateGoogleAuthUrl
      }).catch(err => console.log('error', err))
    }
  })
  
  return (<>{error}</>)
}
