import { useState } from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../layouts/Layout'
import { GenericForm, GenericTable } from '../components'
import getUserQuery from '../gql/queries/getUser'
import ProfileDetail from '../components/ProfileDetail'
import { formatDate } from '../Utils'
import { ProfileUser } from '../types'
import adminGetConversation from '../gql/queries/adminGetConversation'
import NotificationService from '../services/NotificationService'
import CircularProgress from '@material-ui/core/CircularProgress'
import CreateSystemMessageSchema, { initialValues } from './formSchemas/CreateSystemMessageSchema'
import createSystemMessage from '../gql/mutations/createSystemMessage'
import { AdminGetConversationQuery, AdminGetConversationQueryVariables, CreateSystemMessageMutation, CreateSystemMessageMutationVariables, GetUserQuery, GetUserQueryVariables } from '../gql/__generated__'
import { Typography } from '@material-ui/core'
import { useApolloClient } from '@apollo/client'
import useFallibleEffect from '../utils/fallibleEffect'

type TableData = {
  from: string
  to: string
  content: string
  sent: Date
  roomId: string
  read: Date
}

type Props = {}

export default function ConversationDetailPage(_props: Props) {
  const client = useApolloClient()
  const conversationId = useParams().conversationId!

  const [conversation, setConversation] = useState<AdminGetConversationQuery['adminGetConversation']>()
  const [users, setUsers] = useState([] as NonNullable<GetUserQuery['getUser']>[])
  const [isLoaded, setIsLoaded] = useState(false)

  const getConversation = async (id: string) => {
    const result = await client.query<AdminGetConversationQuery, AdminGetConversationQueryVariables>({
      query: adminGetConversation,
      variables: {
        id
      }
    })
    setConversation(result.data.adminGetConversation!)
  }

  const getUser = async (id: string) => {
    const result = await client.query<GetUserQuery, GetUserQueryVariables>({
      query: getUserQuery,
      variables: {
        id
      }
    })
    return result.data.getUser!
  }

  const getInitialValues = () => initialValues

  const handleSubmit = async (values: any) => {
    await client.mutate<CreateSystemMessageMutation, CreateSystemMessageMutationVariables>({
      mutation: createSystemMessage,
      variables: {
        message: {
          toUserId: values.toUserId,
          content: values.content,
          conversationId
        }
      }
    })
    await getConversation(conversationId)
    return true
  }

  const onUpdate = async () => {
    const userIds = conversation!.members.map(member => member.userId)
    const promises = userIds.map(async (id) => {
      return getUser(id)
    })

    const users = await Promise.all(promises).catch(err => NotificationService.showError(err.message))
    if (users && users.length) {
      setUsers(users)
    }
  }
  useFallibleEffect(() => getConversation(conversationId), setIsLoaded, [])
  useFallibleEffect(onUpdate, setIsLoaded, [conversation])

  const columns = [
    {
      Header: 'from',
      accessor: 'from'
    },
    {
      Header: 'to',
      accessor: 'to'
    },
    {
      Header: 'content',
      accessor: 'content'
    },
    {
      Header: 'sent',
      accessor: (item: any) => {
        return formatDate(item.sent)
      }
    },
    {
      Header: 'read',
      accessor: (item: any) => {
        return formatDate(item.read)
      }
    },
    {
      Header: 'roomId',
      accessor: 'roomId'
    },
  ]

  if (!isLoaded || users.length < 2) {
    return (
      <Layout>
        <CircularProgress size={20} thickness={5} />
      </Layout>
    )
  }

  if (CreateSystemMessageSchema.fieldsets[0].fields.length === 1) {
    CreateSystemMessageSchema.fieldsets[0].fields.unshift({
      label: 'To',
      accessor: 'toUserId',
      fieldProps: {
        select: true,
        selectOptions: [
          { label: '(choose)', value: '' },
          ...users.map(user => {
            return { value: user.id, label: `${user.credentials && user.credentials[0].identifier}: ${user.firstName} ${user.lastName}` }
          }),
        ],
      },
    })
  }

  const makeTableData = (): TableData[] => {
    if (!conversation!.messages) {
      return []
    }

    const data = conversation!.messages.map((message: any): TableData | null => {
      const senderUser = users.find(user => user.id === message.fromUserId)
      const targetUser = users.find(user => user.id === message.toUserId)
      return {
          from: senderUser ? `${senderUser.firstName} ${senderUser.lastName}` : 'SYSTEM',
          to: targetUser ? `${targetUser.firstName} ${targetUser.lastName}` : '',
          content: message.content,
          sent: message.sent,
          roomId: message.roomId,
          read: message.read
        }
    })
    return data.filter(d => d) as TableData[]
  }

  return (
    <Layout>
      <Typography variant="h4">Conversation detail - list of messages</Typography>
        <ProfileDetail user={users[0] as ProfileUser} profileId=''/>
        <ProfileDetail user={users[1] as ProfileUser} profileId=''/>
        <GenericForm
          getInitialValues={getInitialValues}
          schema={CreateSystemMessageSchema}
          onSubmit={handleSubmit}
        />
        <GenericTable data={makeTableData()} columns={columns}/>
    </Layout>
  )
}
