import gql from 'graphql-tag'

export default gql`
  query getRatings($profileId: String!) {
    getRatings(profileId: $profileId) {
      id
      ratingTotal
      givingProfileId
      ratedProfileId
      createdAt
      comment
      visible
      ignored
    }
  }
`
