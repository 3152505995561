import gql from 'graphql-tag'

export default gql`
  query adminGetTransactions($profileId: String!) {
    adminGetTransactions(profileId: $profileId) {
      amount
      type
      createdAt
      expiresAt
      sourceId
      comment
    }
  }
`
