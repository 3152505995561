import gql from 'graphql-tag'

export default gql`
  query adminGetPurchasedPackages($studentId: String!) {
    adminGetPurchasedPackages(studentId: $studentId) {
      id
      package {
        id
        lessons {
          id
          lessonUsage
          no
        }
        name
        createdAt
      }
      createdAt
      expiresAt
    }
  }
`
