import { GenericTable } from './GenericTable'
import React from 'react'
import { AdminGetPurchasedPackagesQuery, AdminGetPurchasedPackagesQueryVariables, GetPackagesQuery, GetPackagesQueryVariables } from '../gql/__generated__'
import adminGetPurchasedPackages from '../gql/queries/adminGetPurchasedPackages'
import { formatDate } from '../Utils'
import getPackagesQuery from '../gql/queries/getPackages'
import { GenericForm } from './GenericForm'
import PurchasePackageSchema, { initialValues } from '../pages/formSchemas/PurchasePackageSchema'
import NotificationService from '../services/NotificationService'
import purchasePackageWithTransaction from '../gql/mutations/purchasePackageWithTransaction'
import expirePurchaseMutation from '../gql/mutations/expirePurchase'
import Button from "@material-ui/core/Button";
import { getApolloContext } from '@apollo/client'

type Props = {
  profileId: string
  userId: string
}

type State = {
  isLoaded: boolean,
  purchasedPackages: AdminGetPurchasedPackagesQuery['adminGetPurchasedPackages']
  packages: GetPackagesQuery['getPackages']
}

class StudentPackagesContent extends React.Component<Props, State> {
  state = {
    purchasedPackages: [],
    isLoaded: false,
    packages: []
  }
  static contextType = getApolloContext()
  context!: React.ContextType<ReturnType<typeof getApolloContext>>

  componentDidMount = async () => {
    await Promise.all([ this.getPurchasedPackages(), this.getPackages() ])
      .catch((err: any) => NotificationService.showError(err.toString()))
    await this.setState({ isLoaded: true })
  }

  getPackages = async () => {
    const result = await this.context.client!.query<GetPackagesQuery, GetPackagesQueryVariables>({
      query: getPackagesQuery,
    })
    this.setState({ packages: result.data.getPackages })
  }

  getPurchasedPackages = async () => {
    const { profileId } = this.props
    const result = await this.context.client!.query<AdminGetPurchasedPackagesQuery, AdminGetPurchasedPackagesQueryVariables>({
      query: adminGetPurchasedPackages,
      variables: { studentId: profileId }
    })
    this.setState({ purchasedPackages: result.data.adminGetPurchasedPackages })
  }

  getInitialValues = () => initialValues

  handleSubmit = async (values: any) => {
    const { profileId, userId } = this.props
    await this.context.client!.mutate({
      mutation: purchasePackageWithTransaction,
      variables: {
        packageId: values.packageId,
        profileId,
        userId
      }
    })
    NotificationService.showSuccess('Package successfully purchased')
    window.location.reload()
  }

  handleExpire = async (purchaseId: string) => {
    await this.context.client!.mutate({
      mutation: expirePurchaseMutation,
      variables: {
        purchaseId
      }
    })
    NotificationService.showSuccess('Purchase was expired.')
    window.location.reload()
  }

  render() {
    const columns = [
      {
        Header: 'purchaseId',
        accessor: 'id'
      },
      {
        Header: 'packageId',
        accessor: (item: any) => item.package.id
      },
      {
        Header: 'name',
        accessor: (item: any) => item.package.name
      },
      {
        Header: 'Purchase date',
        accessor: (item: any) => formatDate(item.createdAt)
      },
      {
        Header: 'Used',
        accessor: (item: any) => {
          let countUsed = 0
          item.package.lessons.forEach((lesson: any) => {
            if (lesson.lessonUsage) {
              countUsed++
            }
          })

          return `${countUsed}/${item.package.lessons.length}`
        }
      },
      {
        Header: 'actions',
        accessor: (item: any) => {
          return (
            <>
              {item.expiresAt && item.expiresAt}
              {!item.expiresAt && <Button
                color='primary'
                variant='contained'
                size='small'
                onClick={() => this.handleExpire(item.id)}
              >
                Expire
              </Button>}
            </>
          )
        },
      },
    ]

    if (!this.state.isLoaded) {
      return null
    }

    return (
      <>
        <GenericForm
          getInitialValues={this.getInitialValues}
          schema={PurchasePackageSchema(this.state.packages)}
          onSubmit={this.handleSubmit}
        />
        <GenericTable data={this.state.purchasedPackages} columns={columns}/>
      </>
    )
  }
}

export default StudentPackagesContent
