import gql from 'graphql-tag'

export default gql`
  fragment TutorListFragment on Tutor {
    id
    user {
      id
      firstName
      lastName
      credentials {
        identifier
        type
      }
    }
    language
    balance
    isActive
    slug
  }
`
