import gql from 'graphql-tag'

export default gql`
  query getVouchers {
    getVouchers {
      id
      code
      creditAmount
      maxRedemptions
      redemptions
      creditExpirationAbsolute
      creditExpirationRelative
      expiration
      createdAt
      type
      bonusAmountPercent
    }
  }
`
