import { FieldProps, getIn } from 'formik'
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers'
import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import FormHelperText from '@material-ui/core/FormHelperText'

type FormikDateTimePickerProps = FieldProps

const FormikDateTimePicker = ({
  // Formik field props
  // We prevent "onChange" to passed down to DateTimePicker. Formik needs custom connection here.
  field: { name, onChange, value, ...fieldProps },
  form: { touched, errors, setFieldValue },
  // Datetime picker props
  ...props
}: FormikDateTimePickerProps) => {
  const fieldTouched = getIn(touched, name)
  const error = getIn(errors, name)

  return (
    <React.Fragment>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDateTimePicker
          error={Boolean(fieldTouched && error)}
          fullWidth
          margin='normal'
          openTo='year'
          format='yyyy-MM-dd HH:mm'
          ampm={false}
          // Formik needs custom connection here, onChange doesn't accept DateTime as parameter
          onChange={value => setFieldValue(name, value)}
          // Empty string would cause "Unknown" displayed
          value={value || null}
          {...fieldProps}
          {...props}
        />
      </MuiPickersUtilsProvider>
      {fieldTouched && error && (
        <FormHelperText error={true} style={{ marginLeft: '15px' }}>
          {error}
        </FormHelperText>
      )}
    </React.Fragment>
  )
}

export { FormikDateTimePicker }
