import gql from 'graphql-tag'

export default gql`query adminGetTopics {
  adminGetTopics {
    name
    description
    id
  }
}
`
