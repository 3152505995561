import { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import CircularProgress from '@material-ui/core/CircularProgress'
import Layout from '../layouts/Layout'
import { GenericTable } from "../components";
import findStudentsQuery from '../gql/queries/findStudents'
import getWalletBalancesQuery from '../gql/queries/adminGetWalletBalances'
import { Student, StudentWithBalance, WalletBalance } from '../types'
import PhoneIcon from '@material-ui/icons/Phone'
import PageItem from '../components/PageItem'
import MoneyIcon from '@material-ui/icons/Money'
import StarIcon from '@material-ui/icons/Star'
import MessageIcon from '@material-ui/icons/Message'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import ContactsIcon from '@material-ui/icons/Contacts'
import EmailIcon from '@material-ui/icons/Email'
import BlockIcon from '@material-ui/icons/Block'
import PeopleIcon from '@material-ui/icons/People'
import PackageIcon from '@material-ui/icons/ViewList'
import QuizIcon from '@material-ui/icons/QuestionAnswer'
import { Typography } from '@material-ui/core'
import usePagination from '../utils/pagination';
import useFallibleEffect from '../utils/fallibleEffect';

const styles = {
  pages: {
    display: 'flex',
  },
}

type Props = {} & WithStyles<typeof styles>

function StudentsListPage({ classes }: Props) {
  const client = useApolloClient()

  const [isLoaded, setIsLoaded] = useState(false)
  const [studentsWithBalances, setStudentsWithBalances] = useState([] as StudentWithBalance[])

  const { addToCount, pagination } = usePagination({
    setIsLoaded,
    onUpdate: async (r, p) => { await onUpdate(r, p) },
    rowsPerPageOptions: [30, 50, 100]
  })

  const findStudents = async (first: number, offset: number) => {
    const result = await client.query({
      query: findStudentsQuery,
      variables: {
        first,
        offset,
      },
    })
    return result.data.findStudents
  }

  const getBalances = async (userIds: string[]) => {
    const result = await client.query({ query: getWalletBalancesQuery, variables: { userIds } })
    return result.data.adminGetWalletBalances
  }

  const getStudentsWithBalances = (students: Student[], walletBalances: WalletBalance[]): StudentWithBalance[] => {
    const merged = []

    for (const student of students) {
      const walletBalance = walletBalances.find(wb => wb.userId === student.user.id)
      merged.push({ ...student, balance: (walletBalance && walletBalance.balance) || 0 })
    }

    return merged
  }

  const loadData = async (rowsPerPage: number, page: number) => {
    const students = await findStudents(rowsPerPage, page * rowsPerPage)
    const userIds = students.map((s: any) => s.user.id)
    const balances = await getBalances(userIds)
    return getStudentsWithBalances(students, balances)
  }

  const onUpdate = async (rowsPerPage = pagination.rowsPerPage, page = pagination.page) => {
    const data = await loadData(rowsPerPage, page)
    setStudentsWithBalances(data)
    addToCount(data.length)
  }
  useFallibleEffect(onUpdate, setIsLoaded, [])

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'User',
      accessor: (item: any) => {
        const fullName = `${item.user.firstName} ${item.user.lastName}`
        return fullName.substring(0, 23)
      },
      link: (item: any) => `/profile/${item.id}`,
    },
    {
      Header: 'Email',
      accessor: (item: any) => {
        if (item.user && item.user.credentials && item.user.credentials.length) {
          return item.user.credentials[0].identifier
        } else {
          return ''
        }
      },
    },
    {
      Header: 'Balance',
      accessor: 'balance',
    },
    {
      Header: 'pages',
      accessor: (item: any) => {
        return (
          <div className={classes.pages}>
            <PageItem icon={<MoneyIcon/>} title='Wallets' link={`wallet/${item.id}`}/>
            <PageItem icon={<PhoneIcon/>} title='Calls' link={`profile/${item.id}`}/>
            <PageItem icon={<StarIcon/>} title='Ratings' link={`ratings/${item.id}`}/>
            <PageItem icon={<MessageIcon/>} title='Messages' link={`conversations/${item.id}`}/>
            <PageItem icon={<ContactsIcon/>} title='Contacts' link={`contacts/${item.id}`}/>
            <PageItem icon={<EmailIcon/>} title='Subscriptions' link={`subscriptions/${item.id}`}/>
            <PageItem icon={<BlockIcon/>} title='Ban&Delete' link={`ban/${item.id}`}/>
            <PageItem icon={<PeopleIcon/>} title='Therapists' link={`therapists/${item.id}`}/>
            <PageItem icon={<PackageIcon/>} title='Packages' link={`packages/${item.id}`}/>
            <PageItem icon={<QuizIcon/>} title='Quizzes' link={`quizzes/${item.id}`}/>
          </div>
        )
      },
    },
  ]

  return (
    <Layout>
      <Typography variant="h4">Clients</Typography>
      {!isLoaded ?
        ( <CircularProgress size={20} thickness={5}/> )
        : ( <GenericTable data={studentsWithBalances} pagination={pagination} columns={columns}/> )}
    </Layout>
  )
}

export default withStyles(styles)(StudentsListPage)
