import gql from 'graphql-tag'

export default gql`
  query getProfileWithContacts($id: String!) {
    getProfileFixed(id: $id) {
      ... on Tutor {
        id
        user {
          id
          firstName
          lastName
          credentials {
            identifier
          }
          contacts {
            ... on Email {
              verifiedAt
              email
            }
            ... on Phone {
              phone
            }
            ... on Billing {
              name
              street
              city
              zip
              countryCode
            }
          }
        }
      }
      ... on Student {
        id
        user {
          id
          firstName
          lastName
          credentials {
            identifier
          }
          contacts {
            ... on Email {
              verifiedAt
              email
            }
            ... on Phone {
              phone
            }
            ... on Billing {
              name
              street
              city
              zip
              countryCode
            }
          }
        }
      }
    }
  }
`
