import gql from 'graphql-tag'

export default gql`
  query getQuizzes($profileId: String!) {
    getQuizzes(profileId: $profileId) {
      id
      quiz
    }
  }
`
