import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Tooltip, BarChart, Bar, Legend, XAxis, YAxis } from 'recharts'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import countUnverifiedQuery from '../gql/queries/statsCountUnverifiedUsers'
import countUsersQuery from '../gql/queries/statsCountUsers'
import SimpleSelect from '../components/SimpleSelect'
import moment from 'moment'
import MenuItem from '@material-ui/core/MenuItem'
import { generateDateRanges } from '../Utils'
import { DailyCount, DateRange } from '../types'
import { withStyles } from '@material-ui/core/styles'
import { StatsCountUnverifiedUsersQuery, StatsCountUnverifiedUsersQueryVariables, StatsCountUsersQuery, StatsCountUsersQueryVariables } from '../gql/__generated__'
import { getApolloContext } from '@apollo/client'

const styles = {
  root: {
    marginBottom: 20
  }
}

interface State {
  isLoaded: boolean
  countUnverified: DailyCount[]
  countUsers: DailyCount[]
  ranges: DateRange[]
  selectedRange: string
}

class UsersStats extends React.Component<any, State> {
  state: State = {
    isLoaded: false,
    countUnverified: [],
    countUsers: [],
    ranges: [],
    selectedRange: '2019-05-03',
  }
  static contextType = getApolloContext()
  context!: React.ContextType<ReturnType<typeof getApolloContext>>

  count = async (from: string, to: string) => {
    const countUsersPromise = this.context.client!.query<StatsCountUsersQuery, StatsCountUsersQueryVariables>({
      query: countUsersQuery,
      variables: {
        dateRangeInput: {
          from,
          to
        }
      }
    })
    const countUnverifiedPromise = this.context.client!.query<StatsCountUnverifiedUsersQuery, StatsCountUnverifiedUsersQueryVariables>({
      query: countUnverifiedQuery,
      variables: {
        dateRangeInput: {
          from,
          to
        }
      }
    })
    const [ countUsers, countUnverified ] = await Promise.all([ countUsersPromise, countUnverifiedPromise])
    this.setState({
      countUsers: countUsers.data.statsCountUsers,
      countUnverified: countUnverified.data.statsCountUnverifiedUsers,
      isLoaded: true
    })
  }
  componentDidMount = async () => {
    const ranges = generateDateRanges(4)
    await this.setState({ranges, selectedRange: ranges[0].from})
    await this.count(ranges[0].from, ranges[0].to)
  }
  generateData = () => {
    const data = []
    const { countUsers, countUnverified } = this.state

    for (let i = 0; i < countUsers.length; i++) {
      data.push({ name: countUsers[i].date, users: countUsers[i].count, unverified: countUnverified[i].count })
    }

    return data
  }

  handleChange = async (e: any) => {
    await this.setState({ selectedRange: e.target.value })
    const to = moment(this.state.selectedRange).add(6, 'days').format('YYYY-MM-DD')
    await this.count(this.state.selectedRange, to)
  }

  render() {
    const { classes } = this.props

    if (!this.state.isLoaded) {
      return (
          <CircularProgress size={20} thickness={5} />
      )
    }
    const data = this.generateData()

    const menuItems = () => {
      return this.state.ranges.map(r => {
        return (
          <MenuItem key={r.from} value={ r.from }>
            { `${r.from} - ${r.to}` }
          </MenuItem>
        )
      })
    }

    return (
        <Card classes={{ root: classes.root }}>
          <CardContent>
            <Typography variant="h4">Users</Typography>
            <SimpleSelect handleChange={this.handleChange} value={this.state.selectedRange} menuItems={menuItems()}/>
            <BarChart width={800} height={350} data={data}>
              <XAxis dataKey="name"/>
              <YAxis/>
              <Tooltip/>
              <Legend/>
              <Bar dataKey="users" fill="#80bd00"/>
              <Bar dataKey="unverified" fill="#ff313b"/>
            </BarChart>
            <Typography variant="body1">
              Note: Green color ("users") are users who completed profile. Unverified are those who didn't finish registration (lost e-mail, left during profile creation, etc).
            </Typography>
          </CardContent>
        </Card>
    )
  }
}

export default withStyles(styles)(UsersStats)
