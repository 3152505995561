import CircularProgress from '@material-ui/core/CircularProgress'

import Layout from '../layouts/Layout'
import { Alert, GenericTable } from '../components'
import getAwaitingVerificationCredentialsQuery from '../gql/queries/getAwaitingVerificationCredentials'
import { useQuery } from '@apollo/client'
import { GetAwaitingVerificationCredentialsQuery } from '../gql/__generated__'

type Props = {}

export default function AwaitingVerificationListPage(_props: Props) {
  const columns = [
    {
      Header: 'identifier',
      accessor: (item: any) => item.identifier
    },
    {
      Header: 'Generate new link',
      accessor: (item: any) => `Generate new link`,
      link: (item: any) => `/awaiting-verification/${item.identifier}`
    }
  ]

  const { data, loading, error } = useQuery<GetAwaitingVerificationCredentialsQuery>(getAwaitingVerificationCredentialsQuery)
  return (
    <Layout>
      <div>
        {loading && <CircularProgress size={20} thickness={5} />}
        {!loading && error && <Alert>{JSON.stringify(error)}</Alert>}
        {!loading && data && (
          <div>
            <GenericTable data={data.getAwaitingVerificationCredentials} columns={columns} />
          </div>
        )}
      </div>
    </Layout>
  )
}
