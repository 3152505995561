import gql from 'graphql-tag'

const InviteGroupMember = gql`
  mutation InviteGroupMember(
    $department: String
    $firstName: String!
    $groupId: String!
    $groupRole: GroupRole!
    $identifier: String!
    $lastName: String!
    $state: GroupUserState!
    $walletOptions: WalletOptionsInput!
  ) {
    groupMember: inviteGroupMember(
      department: $department
      identifier: $identifier
      firstName: $firstName
      groupId: $groupId
      groupRole: $groupRole
      lastName: $lastName
      state: $state
      walletOptions: $walletOptions
    ) {
      ...InviteGroupMemberStudent
    }
  }

  fragment InviteGroupMemberStudent on GroupStudent {
    id
    department
    firstName
    groupRole
    lastName
    identifier
    state
    wallet {
      id
      options {
        coverage
        monthlyLimit
      }
    }
  }
`

export default InviteGroupMember
