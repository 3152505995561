import { useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useApolloClient } from '@apollo/client'
import Layout from '../layouts/Layout'
import { GenericTable } from '../components'
import Button from '@material-ui/core/Button'
import getTutorsQuery from '../gql/queries/getTutors'
import assignTherapistMutation from '../gql/mutations/assignTherapist'
import NotificationService from '../services/NotificationService'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import { Typography } from '@material-ui/core'
import ButtonLink from '../components/ButtonLink'
import { useParams } from 'react-router-dom'
import { AssignTherapistMutation, AssignTherapistMutationVariables, GetClientTherapistIdQuery, GetClientTherapistIdQueryVariables, GetTutorsQuery, GetTutorsQueryVariables } from '../gql/__generated__'
import useFallibleEffect from '../utils/fallibleEffect'
import getClientTherapistId from '../gql/queries/getClientTherapistId'
import usePagination from '../utils/pagination'

const styles = {
  pages: {
    display: 'flex',
  },
}

type Props = {} & WithStyles

function TherapistsPage({ classes }: Props) {
  const studentId = useParams().studentId!
  const client = useApolloClient()

  const [tutors, setTutors] = useState([] as GetTutorsQuery['getTutors'])
  const [assignedTutorId, setAssignedTutorId] = useState<string | undefined>()
  const [isLoaded, setIsLoaded] = useState(false)
  const { addToCount, pagination } = usePagination({
    setIsLoaded,
    onUpdate: async (r, p) => { await onUpdate(r, p) },
    rowsPerPageOptions: [200]
  })

  const handleClick = async (studentId: string, tutorId: string) => {
    await client.mutate<AssignTherapistMutation, AssignTherapistMutationVariables>({
      mutation: assignTherapistMutation,
      variables: {
        tutorId,
        studentId,
      },
    })
    await getTutors(pagination.rowsPerPage, pagination.page * pagination.rowsPerPage)
    NotificationService.showSuccess('Therapist was assigned successfully.')
  }

  const getTutors = async (first: number, offset: number) => {
    const result = await client.query<GetTutorsQuery, GetTutorsQueryVariables>({
      query: getTutorsQuery,
      variables: {
        first,
        offset
      }
    })
    setTutors(result.data.getTutors)
    addToCount(result.data.getTutors.length)
  }
  const getAssignedTherapist = async () => {
    try {
      const result = await client.query<GetClientTherapistIdQuery, GetClientTherapistIdQueryVariables>({
        query: getClientTherapistId,
        variables: {
          clientId: studentId
        }
      })
      setAssignedTutorId(result.data.adminGetClientTherapistId.tutorId)
    } catch (err) {
      throw new Error('no therapist assigned yet')
    }
  }
  const onUpdate = async (rowsPerPage = pagination.rowsPerPage, page = pagination.page) => {
    await getTutors(rowsPerPage, page)
  }
  useFallibleEffect(getAssignedTherapist, setIsLoaded, [])
  useFallibleEffect(onUpdate, setIsLoaded, [])

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'User',
      accessor: (item: any) => `${item.user.firstName} ${item.user.lastName}`,
      link: (item: any) => `/profile/${item.id}`,
    },
    {
      Header: 'Email',
      accessor: (item: any) => {
        const passwordCredential = item.user.credentials.find((credential: any) => credential.type === 'Password')
        return passwordCredential ? passwordCredential.identifier : ''
      },
    },
    {
      Header: 'actions',
      accessor: (item: any) => {
        return (
          <>
            {item.id !== assignedTutorId && (<Button
              color='primary'
              variant='contained'
              size='small'
              onClick={() => handleClick(studentId, item.id)}
            >
              Assign
            </Button>)}
          </>
        )
      },
    },
  ]

  return (
    <Layout>
      <Typography variant='h4'>Assigned Therapist ({studentId})</Typography>
      <p>
        <ButtonLink link={`/profile/${studentId}`}>Back</ButtonLink>
      </p>
      {!isLoaded ? (
        <CircularProgress size={20} thickness={5} />
      ) : (
        <GenericTable data={tutors} pagination={pagination} columns={columns} />
      )}
    </Layout>
  )
}

export default withStyles(styles)(TherapistsPage)
