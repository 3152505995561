import gql from 'graphql-tag'

export default gql`
  query getWallets($userId: String!) {
    getWallets(userId: $userId) {
      balance
      type
    }
  }
`
