import React, { ReactChild, SyntheticEvent } from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
  children: ReactChild
  to: string
  passHref?: boolean
}

export function RouterLink({ children, to, passHref }: Props) {
  const navigate = useNavigate()

  return (
    <>{
      React.Children.map(children, child => {
        return React.cloneElement(child as React.ReactElement<any>, {
          href: passHref ? to : undefined,
          onClick: (ev: SyntheticEvent) => {
            ev.preventDefault()
            navigate(to)
          },
        })
      })
    }</>
  )
}
