import { FormSchema } from '../../components'

const initialValues = {
  amount: 0.01,
  createdAt: ''
}

const TutorPayoutSchema: FormSchema = {
  fieldsets: [
    {
      title: 'Tutor payout',
      fields: [
        {
          label: 'Amount($)',
          accessor: 'amount'
        },
        {
          label: 'Comment for tutor',
          accessor: 'comment'
        },
      ]
    }
  ]
}

export default TutorPayoutSchema

export { initialValues }
