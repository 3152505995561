import { IconButton, Typography } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close';
import React from 'react'
import { makeStyles } from '@material-ui/styles'

type Props = {
  title: string
  onClose: () => void
}

const useStyles = makeStyles({
  root: {
    margin: 0,
    padding: 10
  },
  closeButton: {
    position: 'absolute',
    right: 5,
    top: 5,
    color: 'grey'
  },
  title: {
    textAlign: 'center',
    marginTop: 5
  }
})

const DialogTitle = ({ title, onClose }: Props) => {
  const classes = useStyles({})
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h5" className={classes.title}>{title}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

export default DialogTitle
