import { useState } from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../layouts/Layout'
import { GenericTable } from '../components'
import CircularProgress from '@material-ui/core/CircularProgress'
import getProfileQuery from '../gql/queries/getProfile'
import ProfileDetail from '../components/ProfileDetail'
import { formatDate } from '../Utils'
import { useApolloClient } from '@apollo/client'
import { ProfileUser } from '../types'
import adminGetConversations from '../gql/queries/adminGetConversations'
import UserPagesNavigation from '../components/UserPagesNavigation'
import { UserPagesTitles } from '../enums'
import { Typography } from '@material-ui/core'
import { AdminGetConversationsQuery, AdminGetConversationsQueryVariables, GetProfileQuery, GetProfileQueryVariables } from '../gql/__generated__'
import usePagination from '../utils/pagination'
import useFallibleEffect from '../utils/fallibleEffect'

type Props = {}

export default function ConversationsListPage(_props: Props) {
  const profileId = useParams().profileId!
  const client = useApolloClient()

  const [isLoaded, setIsLoaded] = useState(false)
  const [conversations, setConversations] = useState([] as AdminGetConversationsQuery['adminGetConversations'])
  const [user, setUser] = useState<ProfileUser | undefined>()
  const { addToCount, pagination } = usePagination({ setIsLoaded, onUpdate: (r, p) => onUpdate(r, p) })

  const getProfile = async (id: string) => {
    const result = await client.query<GetProfileQuery, GetProfileQueryVariables>({
      query: getProfileQuery,
      variables: {
        id,
      },
    })
    return result.data.getProfileFixed
  }

  const getConversations = async (userId: string, first: number, offset: number) => {
    const result = await client.query<AdminGetConversationsQuery, AdminGetConversationsQueryVariables>({
      query: adminGetConversations,
      variables: {
        userId,
        pageInfo: {
          first,
          offset,
        },
      },
    })
    return result.data.adminGetConversations
  }

  const onUpdate = async (rowsPerPage = pagination.rowsPerPage, page = pagination.page) => {
    const conversations = await getConversations(user!.id, rowsPerPage, rowsPerPage * page)
    setConversations(conversations)
    addToCount(conversations.length)
  }

  useFallibleEffect(() => getProfile(profileId).then(p => setUser(p.user)), setIsLoaded, [])
  useFallibleEffect(onUpdate, setIsLoaded, [user])

  let profileType = 'Student'
  if (profileId.startsWith('ST')) {
    profileType = 'Tutor'
  }
  const columns = [
    {
      Header: 'conversationId',
      accessor: 'id',
    },
    {
      Header: profileType,
      accessor: (item: any) => `${item.user.firstName} ${item.user.lastName}`,
      link: (item: any) => {
        if (item.user.tutors.length) {
          return `/profile/${item.user.tutors[0].id}`
        }
        return `/profile/${item.user.students[0].id}`
      },
    },
    {
      Header: 'lastMessageAt',
      accessor: (item: any) => {
        return formatDate(item.lastMessage && item.lastMessage.sent)
      },
    },
    {
      Header: 'messages',
      accessor: (item: any) => `Messages`,
      link: (item: any) => `/conversation/${item.id}`,
    },
  ]

  return (
    <Layout>
      <Typography variant='h4'>Profile detail - list of conversations</Typography>
      {user && <UserPagesNavigation profileId={profileId} title={UserPagesTitles.Conversations} />}
      {user && (
        <div>
          <ProfileDetail user={user} profileId={profileId} />
        </div>
      )}
      {!user && <CircularProgress size={20} thickness={5} />}
      {isLoaded && (
        <div>
          <GenericTable data={conversations} columns={columns} pagination={pagination} />
        </div>
      )}
      {!isLoaded && <CircularProgress size={20} thickness={5} />}
    </Layout>
  )
}