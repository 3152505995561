import gql from 'graphql-tag'

export default gql`
  mutation invalidateCoupon($couponId: String!) {
    invalidate(couponId: $couponId) {
      id
      createdAt
      canceledAt
      code
      expiryDate
      multiAccount
      usesAvailable
      usesPerAccount
      note
      package {
        id
        packageId
      }
    }
  }
`
