import React from 'react'
import { CSVLink } from 'react-csv'
import Button from '@material-ui/core/Button'
import moment from 'moment'

type Props = {
  data: any
}

const CSVExport = ({ data }: Props) => {
  const headers = [
    {
      label: 'Code',
      key: 'code',
    },
  ]

  const filename = `export_coupons_${moment(new Date()).format('YYYY-MM-DD')}`

  return (
    <>
      <CSVLink headers={headers} data={data} filename={filename} style={{ textDecoration: 'none', color: 'black' }}>
        <Button type='button' variant='contained' color='primary' style={{ marginBottom: '2em' }}>
          CSV Export
        </Button>
      </CSVLink>
    </>
  )
}

export default CSVExport
