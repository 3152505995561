import React from 'react'
import Typography from '@material-ui/core/Typography'

import Layout from '../layouts/Layout'
import { FormSchema, GenericForm, GenericTable } from '../components'
import NotificationService, {
  MessageType,
} from '../services/NotificationService'
import adminCreateTopic from '../gql/mutations/adminCreateTopic'
import adminGetTopics from '../gql/queries/adminGetTopics'
import { getApolloContext } from '@apollo/client'
import { AdminCreateTopicMutation, AdminCreateTopicMutationVariables, AdminGetTopicsQuery, AdminGetTopicsQueryVariables } from '../gql/__generated__'

type Props = {}

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Description',
    accessor: 'description',
  }
]

const TopicsFormSchema: FormSchema = {
  fieldsets: [
    {
      title: 'Add topic',
      fields: [
        {
          label: 'Name',
          accessor: 'name',
        },
        {
          label: 'Description',
          accessor: 'description',
        }
      ]
    }
  ]
}

class TopicsPage extends React.Component<Props> {
  state = {
    form: {
      name: '',
      description: ''
    },
    topics: []
  }
  static contextType = getApolloContext()
  context!: React.ContextType<ReturnType<typeof getApolloContext>>

  getInitialValues = () => {
    return this.state.form
  }

  getTopics = async () => {
    const topics = await this.context.client!.query<AdminGetTopicsQuery, AdminGetTopicsQueryVariables>({ query: adminGetTopics })
    await this.setState({ topics: topics.data.adminGetTopics })
    return topics.data.adminGetTopics
  }

  componentDidMount = async () => {
    await this.getTopics()
  }

  handleSubmit = async (values: any) => {
    await this.context.client!.mutate<AdminCreateTopicMutation, AdminCreateTopicMutationVariables>({
      mutation: adminCreateTopic,
      variables: {
        topicInput: values
      }
    })
    NotificationService.show(MessageType.success, 'Topic added successfully')
    this.getTopics()
    return true
  }

  render() {
    return (
      <Layout>
        <Typography gutterBottom={true} variant='h4'>
          Topics
        </Typography>
        <GenericForm
          getInitialValues={this.getInitialValues}
          schema={TopicsFormSchema}
          onSubmit={this.handleSubmit}
        />
        <GenericTable data={this.state.topics} columns={columns} />
      </Layout>
    )
  }
}

export default TopicsPage
