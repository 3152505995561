import Typography from '@material-ui/core/Typography'
import { useState } from 'react'
import adminGetTutorQuery from '../gql/queries/adminGetTutor'
import Layout from '../layouts/Layout'
import { FormSchema, GenericForm, RouterLink } from '../components'
import { tutorFormSchema, initialValues } from './formSchemas/TutorFormSchema'
import { removeTypenameFromData } from '../api/GraphQLUtils'
import NotificationService from '../services/NotificationService'
import Button from '@material-ui/core/Button'
import adminUpdateTutorMutation from '../gql/mutations/adminUpdateTutor'
import adminCreateTutorMutation from '../gql/mutations/adminCreateTutor'
import adminGetTopics from '../gql/queries/adminGetTopics'
import { useNavigate, useParams } from 'react-router'
import { useApolloClient } from '@apollo/client'
import { AdminGetTopicsQuery, AdminGetTopicsQueryVariables, TutorCreateMutationMutation, TutorCreateMutationMutationVariables, TutorUpdateMutationMutation, TutorUpdateMutationMutationVariables, TutorUpdateQueryQuery, TutorUpdateQueryQueryVariables } from '../gql/__generated__'
import useFallibleEffect from '../utils/fallibleEffect'
import CircularProgress from '@material-ui/core/CircularProgress'

type Props = {}

export default function TutorUpdatePage(_: Props) {
  const tutorId = useParams().tutorId
  const client = useApolloClient()
  const navigate = useNavigate()
  
  const [isLoaded, setIsLoaded] = useState(false)
  const [formSchema, setFormSchema] = useState<FormSchema | undefined>()

  const getInitialValues = async () => {
    if (tutorId != undefined) {
      const result = await client.query<TutorUpdateQueryQuery, TutorUpdateQueryQueryVariables>({
        query: adminGetTutorQuery,
        variables: { id: tutorId },
      })
      return removeTypenameFromData(result.data.adminGetTutor)
    } else {
      return initialValues
    }
  }

  const handleSubmit = async (values: any) => {
    const image = values.user.image?.[0]
    delete values.user.image
    delete values.topics // We want to send only topicIds as an argument

    values.user.credential = values.user.credentials?.[0]
    delete values.user.credentials

    try {
      if (tutorId != undefined) {
        await client.mutate<TutorUpdateMutationMutation, TutorUpdateMutationMutationVariables>({
          mutation: adminUpdateTutorMutation,
          variables: {
            tutor: values,
            image,
          },
        })
      } else {
        await client.mutate<TutorCreateMutationMutation, TutorCreateMutationMutationVariables>({
          mutation: adminCreateTutorMutation,
          variables: {
            tutor: values,
            image,
          },
          errorPolicy: 'none'
        })
      }
      NotificationService.showSuccess('Saved successfully')
      navigate('/tutors')
    } catch (err: any) {
      NotificationService.showError(err)
    }
  }

  useFallibleEffect(async () => {
    const res = await client.query<AdminGetTopicsQuery, AdminGetTopicsQueryVariables>({ query: adminGetTopics })
    const topics = res.data.adminGetTopics

    setFormSchema(tutorFormSchema(
      topics.map(t => ({ label: t.name, value: t.id }))
    ))
  }, setIsLoaded, [])

  if (!isLoaded) {
    return (
      <Layout>
        <CircularProgress size={20} thickness={5} />
      </Layout>
    )
  }
  return (
    <Layout>
      <Typography gutterBottom={true} variant='h1'>
        {tutorId != undefined ? 'Edit tutor' : 'Create tutor'}
        <RouterLink to='/tutors'>
          <Button color='secondary' style={{ float: 'right' }} variant='contained'>
            Back to tutors
          </Button>
        </RouterLink>
      </Typography>
      <GenericForm getInitialValues={getInitialValues} schema={formSchema} onSubmit={handleSubmit} />
    </Layout>
  )
}
