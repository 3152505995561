import gql from 'graphql-tag'

export default gql`
  mutation setRatingIgnored($id: String!, $ignored: Boolean!) {
    setRatingIgnored (id: $id, ignored: $ignored) {
      id
      ignored
    }
  }
`
