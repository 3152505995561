import gql from 'graphql-tag'

const GroupDetail = gql`
  query GroupDetail($groupId: String!) {
    group: serviceGetGroup(groupId: $groupId) {
      id
      name
    }
    students: getGroupStudents(groupId: $groupId) {
      ...GroupDetailStudent
    }
  }

  fragment GroupDetailStudent on GroupStudent {
    id
    department
    firstName
    lastName
    groupRole
    identifier
    state
    wallet {
      id
      balance
      options {
        coverage
        monthlyLimit
      }
    }
  }
`

export default GroupDetail
