import { FormSchema } from '../../components'
import * as Yup from 'yup'
import { CouponFiltersInput, CouponFilterState } from '../../gql/__generated__'

export const initialValues: CouponFiltersInput = {
  state: CouponFilterState.Available,
  text: '',
}

export const validationSchema = Yup.object().shape({
  state: Yup.mixed<CouponFilterState>().oneOf([
    CouponFilterState.Available,
    CouponFilterState.UsedUp,
    CouponFilterState.All,
  ]),
  text: Yup.string(),
})

const stateFieldsProps = {
  select: true,
  selectOptions: [
    {
      label: 'Available coupons',
      value: CouponFilterState.Available,
    },
    {
      label: 'Used up coupons',
      value: CouponFilterState.UsedUp,
    },
    {
      label: 'All coupons',
      value: CouponFilterState.All,
    },
  ],
}

const FilterCouponSchema = (): FormSchema => ({
  fieldsets: [
    {
      title: 'Filter coupons',
      fields: [
        {
          label: 'Coupon state',
          accessor: 'state',
          fieldProps: stateFieldsProps,
        },
        {
          label: 'Text',
          accessor: 'text',
        },
      ],
    },
  ],
})

export default FilterCouponSchema
