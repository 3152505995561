import { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Layout from '../layouts/Layout'
import { useApolloClient } from '@apollo/client'
import getRatingQuery from '../gql/queries/getRating'
import getProfileQuery from '../gql/queries/getProfile'
import RatingDetail from '../components/RatingDetail'
import { GenericForm } from '../components'
import EditRatingCommentSchema from './formSchemas/EditRatingCommentSchema'
import editRatingCommentMutation from '../gql/mutations/editRatingComment'
import NotificationService from '../services/NotificationService'
import { Typography } from '@material-ui/core'
import { AdminEditRatingCommentMutation, AdminEditRatingCommentMutationVariables, GetProfileQuery, GetProfileQueryVariables, GetRatingQuery, GetRatingQueryVariables } from '../gql/__generated__'
import useFallibleEffect from '../utils/fallibleEffect'

type Props = {}

export default function RatingDetailPage(_props: Props) {
  const ratingId = useParams().ratingId!
  const navigate = useNavigate()
  const client = useApolloClient()
  
  const [rating, setRating] = useState<GetRatingQuery['getRating']>()
  const [ratedProfile, setRatedProfile] = useState<GetProfileQuery['getProfileFixed']>()
  const [givingProfile, setGivingProfile] = useState<GetProfileQuery['getProfileFixed']>()
  const [isLoaded, setIsLoaded] = useState(false)

  const getRating = async () => {
    const result = await client.query<GetRatingQuery, GetRatingQueryVariables>({
      query: getRatingQuery,
      variables: {
        id: ratingId
      }
    })
    setRating(result.data.getRating)
  }

  const getProfile = async (id: string) => {
    const result = await client.query<GetProfileQuery, GetProfileQueryVariables>({
      query: getProfileQuery,
      variables: {
        id
      }
    })
    return result.data.getProfileFixed
  }

  const handleSubmit = async (values: any) => {
    await client.mutate<AdminEditRatingCommentMutation, AdminEditRatingCommentMutationVariables>({
      mutation: editRatingCommentMutation,
      variables: {
        id: rating!.id,
        comment: values.comment
      }
    })
    navigate(`/ratings/${ratedProfile!.id}`)
    NotificationService.showSuccess('Comment successfully updated')
    return true
  }

  const getInitialValues = () => {
    return {
      comment: rating!.comment
    }
  }

  const onUpdate = async () => {
    const givingProfilePromise = getProfile(rating!.givingProfileId)
    const ratedProfilePromise = getProfile(rating!.ratedProfileId)
    const [givingProfile, ratedProfile] = await Promise.all([ givingProfilePromise, ratedProfilePromise ])
    setRatedProfile(ratedProfile)
    setGivingProfile(givingProfile)
  }
  useFallibleEffect(getRating, setIsLoaded, [])
  useFallibleEffect(onUpdate, setIsLoaded, [rating])
  
  if (!isLoaded) {
    return null
  }

  return (
    <Layout>
      <Typography variant="h4">Rating - edit comment</Typography>
      <RatingDetail rating={rating!} givingUser={givingProfile!.user as any} ratedUser={ratedProfile!.user as any}/>
      <GenericForm
        getInitialValues={getInitialValues}
        schema={EditRatingCommentSchema}
        onSubmit={handleSubmit}
      />
    </Layout>
  )
}
