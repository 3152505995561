import gql from 'graphql-tag'

export default gql`
  query getOnlineTutors {
    getOnlineTutors {
      user {
        id
        firstName
        lastName
        statuses {
          until
        }
      }
      id
    }
  }
`
