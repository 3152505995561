import { FormSchema } from '../../components'
import { ContactType } from '../../gql/__generated__'

const initialValues = {
  contact: '',
  type: ''
}

const AddContactSchema: FormSchema = {
  fieldsets: [
    {
      title: 'Add Contact',
      fields: [
        {
          label: 'contact',
          accessor: 'contact'
        },
        {
          label: 'type',
          accessor: 'type',
          fieldProps: {
            select: true,
            selectOptions: [
              { label: 'choose', value: '' },
              { label: 'Mail', value: ContactType.Email },
              { label: 'Phone', value: ContactType.Phone },
            ]
          }
        }
      ]
    }
  ]
}

export default AddContactSchema

export { initialValues }
