import getPackagesQuery from '../../gql/queries/getPackages'
import getCouponsQuery from '../../gql/queries/getCoupons'
import getAllCouponsQuery from '../../gql/queries/getAllCoupons'
import createCouponQuery from '../../gql/mutations/createCoupon'
import createCouponBatchQuery from '../../gql/mutations/createCouponBatch'
import invalidateCouponQuery from '../../gql/mutations/invalidateCoupon'
import { formatDate } from '../../Utils'
import { CouponBatchInput, CouponFiltersInput, CouponInput, CreateCouponBatchMutation, CreateCouponBatchMutationVariables, CreateCouponMutation, CreateCouponMutationVariables, GetAllCouponsQuery, GetAllCouponsQueryVariables, GetCouponsQuery, GetCouponsQueryVariables, GetPackagesQuery, GetPackagesQueryVariables, InvalidateCouponMutation, InvalidateCouponMutationVariables, RefreshCouponSummaryMutation, RefreshCouponSummaryMutationVariables } from '../../gql/__generated__'
import refreshCouponSummary from '../../gql/mutations/refreshCouponSummary'
import { ApolloClient } from '@apollo/client'

export const getPackages = async (client: ApolloClient<any>) => {
  const result = await client.query<GetPackagesQuery, GetPackagesQueryVariables>({
    query: getPackagesQuery,
  })
  return result.data.getPackages
}

export const getCoupons = async (client: ApolloClient<any>, filters: CouponFiltersInput, first = 0, offset = 50) => {
  const result = await client.query<GetCouponsQuery, GetCouponsQueryVariables>({
    query: getCouponsQuery,
    variables: {
      filters,
      pageInfo: {
        first,
        offset,
      }
    },
  })

  return result.data.getCoupons
}

export const getAllCoupons = async (client: ApolloClient<any>, filters: CouponFiltersInput) => {
  const result = await client.query<GetAllCouponsQuery, GetAllCouponsQueryVariables>({
    query: getAllCouponsQuery,
    variables: {
      filters,
    },
  })

  return result.data.getAllCoupons
}

export const refreshCouponList = async (client: ApolloClient<any>) => {
  await client.mutate<RefreshCouponSummaryMutation, RefreshCouponSummaryMutationVariables>({
    mutation: refreshCouponSummary,
  })
}

export const createCoupon = async (client: ApolloClient<any>, coupon: CouponInput) => {
  const result = await client.mutate<CreateCouponMutation, CreateCouponMutationVariables>({
    mutation: createCouponQuery,
    variables: {
      data: coupon,
    },
  })
  await refreshCouponList(client)

  return result.data!.createCoupon
}

export const createCouponBatch = async (client: ApolloClient<any>, couponBatch: CouponBatchInput) => {
  const result = await client.mutate<CreateCouponBatchMutation, CreateCouponBatchMutationVariables>({
    mutation: createCouponBatchQuery,
    variables: {
      data: couponBatch,
    },
  })
  await refreshCouponList(client)

  return result.data!.createCouponBatch
}

export const invalidateCoupon = async (client: ApolloClient<any>, couponId: string) => {
  const result = await client.mutate<InvalidateCouponMutation, InvalidateCouponMutationVariables>({
    mutation: invalidateCouponQuery,
    variables: {
      couponId,
    },
  })
  await refreshCouponList(client)

  return result.data!.invalidate
}

export const createCouponInput = (values: any): CouponInput => {
  if (values.code === '') {
    throw Error('Coupon code must be entered')
  }

  if (values.packageId === '') {
    throw Error('Package id must be entered')
  }

  const coupon: CouponInput = {
    code: values.code,
    packageId: values.packageId,
  }

  if (values.expiryDate) {
    coupon.expiryDate = formatDate(values.expiryDate)
  }

  if (values.multiAccount) {
    coupon.multiAccount = Boolean(values.multiAccount)
  }

  if (values.usesAvailable) {
    coupon.usesAvailable = Number(values.usesAvailable)
  }

  if (values.usesPerAccount) {
    coupon.usesPerAccount = Number(values.usesPerAccount)
  }

  if (values.note) {
    coupon.note = values.note
  }

  return coupon
}

export const createCouponBatchInput = (values: any): CouponBatchInput => {
  if (values.numberOfCoupons === '') {
    throw Error('Number of coupons must be entered')
  }

  if (values.lengthOfCode === '') {
    throw Error('Length of code must be entered')
  }

  if (values.packageId === '') {
    throw Error('Package id must be entered')
  }

  const coupon: CouponBatchInput = {
    numberOfCoupons: Number(values.numberOfCoupons),
    lengthOfCode: Number(values.lengthOfCode),
    packageId: values.packageId,
  }

  if (values.expiryDate) {
    coupon.expiryDate = formatDate(values.expiryDate)
  }

  if (values.multiAccount) {
    coupon.multiAccount = Boolean(values.multiAccount)
  }

  if (values.usesAvailable) {
    coupon.usesAvailable = Number(values.usesAvailable)
  }

  if (values.usesPerAccount) {
    coupon.usesPerAccount = Number(values.usesPerAccount)
  }

  if (values.note) {
    coupon.note = values.note
  }

  return coupon
}
