import { useState } from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../layouts/Layout'
import { GenericTable } from '../components'
import CircularProgress from '@material-ui/core/CircularProgress'
import getProfileQuery from '../gql/queries/getProfile'
import ProfileDetail from '../components/ProfileDetail'
import { useApolloClient } from '@apollo/client'
import NotificationService from '../services/NotificationService'
import { ProfileUser } from '../types'
import UserPagesNavigation from '../components/UserPagesNavigation'
import { UserPagesTitles } from '../enums'
import { Typography } from '@material-ui/core'
import { GetProfileQuery, GetProfileQueryVariables, GetQuizzesQuery, GetQuizzesQueryVariables } from '../gql/__generated__'
import getQuizzesQuery from '../gql/queries/getQuizzes'
import useFallibleEffect from '../utils/fallibleEffect'

type QuizQuestion = {
  question: string
  answer: string
}

type Props = {}

export default function QuizzesPage(_props: Props) {
  const profileId = useParams().profileId!
  const client = useApolloClient()

  const [quiz, setQuiz] = useState([] as QuizQuestion[])
  const [isLoaded, setIsLoaded] = useState(false)
  const [user, setUser] = useState<ProfileUser | undefined>()

  const createQuizData = (quizzes: GetQuizzesQuery['getQuizzes']): QuizQuestion[] => {
    return Object.entries(quizzes[0].quiz).map(([ key, value ]: any) => {
      return {
        question: key,
        answer: value.toString()
      }
    })
  }

  const getQuizzes = async () => {
    const result = await client.query<GetQuizzesQuery, GetQuizzesQueryVariables>({
      query: getQuizzesQuery,
      variables: { profileId }
    })
    return result.data.getQuizzes
  }

  const componentDidMount = async () => {
    let quizzes
    try {
      const profile = await getProfile(profileId)
      setUser(profile.user)
      quizzes = await getQuizzes()
    } catch (err) {
      NotificationService.showError('Cannot get quizzes')
    }

    if (quizzes && quizzes.length) {
      const quizData = createQuizData(quizzes)
      setQuiz(quizData)
    }
  }
  useFallibleEffect(componentDidMount, setIsLoaded, [])

  const getProfile = async (id: string) => {
    const result = await client.query<GetProfileQuery, GetProfileQueryVariables>({
      query: getProfileQuery,
      variables: {
        id
      }
    })
    return result.data.getProfileFixed
  }

  const columns = [
    {
      Header: 'Question',
      accessor: 'question'
    },
    {
      Header: 'Answer',
      accessor: 'answer'
    }
  ]

  if (!isLoaded) {
    return (
      <Layout>
        <CircularProgress size={20} thickness={5} />
      </Layout>
    )
  }

  return (
    <Layout>
      <Typography variant="h4">Profile detail - Quizzes</Typography>
      <UserPagesNavigation profileId={profileId} title={UserPagesTitles.Quizzes} />
      <div>
        <ProfileDetail user={user!} profileId={profileId}/>
      </div>
      <div>
        <GenericTable title={`Quiz`} data={quiz} columns={columns}/>
      </div>
    </Layout>
  )
}
