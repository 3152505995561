import CircularProgress from '@material-ui/core/CircularProgress'
import { useApolloClient } from '@apollo/client'
import Layout from '../layouts/Layout'
import { Alert, GenericTable } from '../components'
import Button from '@material-ui/core/Button'
import getCredentialsWithoutHashQuery from '../gql/queries/getCredentialsWithoutHash'
import sendVerificationMutation from '../gql/mutations/sendVerification'
import { useQuery } from '@apollo/client'
import { GetCredentialsWithoutHashQuery, SendVerificationMutation, SendVerificationMutationVariables } from '../gql/__generated__'

type Props = {}

export default function VerifyUsersPage(_props: Props) {
  const client = useApolloClient()

  const handleClick = async (item: any) => {
    await client.mutate<SendVerificationMutation, SendVerificationMutationVariables>({
      mutation: sendVerificationMutation,
      variables: {
        identifier: item.identifier
      }
    })
  }
  
  const columns = [
    {
      Header: 'identifier',
      accessor: (item: any) => item.identifier
    },
    {
      Header: 'Verify',
      accessor: (item: any) => <Button color='secondary' variant='contained' size='small' onClick={() => handleClick(item)}>Verify</Button>
    }
  ]

  const { data, loading, error } = useQuery<GetCredentialsWithoutHashQuery>(getCredentialsWithoutHashQuery)
  return (
    <Layout>
    <div>
      {loading && <CircularProgress size={20} thickness={5} />}
      {!loading && error && <Alert>{JSON.stringify(error)}</Alert>}
      {!loading && data && (
        <div>
          <GenericTable data={data.getCredentialsWithoutHash} columns={columns} />
        </div>
      )}
    </div>
  </Layout>
  )
}
