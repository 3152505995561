import { GroupRole } from '../../gql/__generated__'

type SortableGroupStudent = {
  groupRole: GroupRole
}

const groupRoleToScore = (groupRole: GroupRole) => {
  switch (groupRole) {
    case GroupRole.GroupAdmin:
      return 20
    case GroupRole.GroupManager:
      return 10
    case GroupRole.GroupUser:
      return 0
  }
}

export const sortGroupStudents = <GroupStudent extends SortableGroupStudent>(
  groupStudents: GroupStudent[],
): GroupStudent[] =>
  [...groupStudents].sort((a, b) => {
    const aRole = groupRoleToScore(a.groupRole)
    const bRole = groupRoleToScore(b.groupRole)

    return aRole > bRole ? -1 : aRole < bRole ? 1 : 0
  })
