import gql from 'graphql-tag'

const GroupAddMember = gql`
  query GroupAddMember($groupId: String!) {
    group: serviceGetGroup(groupId: $groupId) {
      id
      name
    }
  }
`

export default GroupAddMember
