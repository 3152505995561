import gql from 'graphql-tag'

export default gql`
  query adminGetAllShiftsInRange($from: DateTime!, $to: DateTime!) {
    adminGetAllShiftsInRange(from: $from, to: $to) {
      id
      start
      end
      cancelledAt
      createdAt
      type
      tutor {
        id
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`
