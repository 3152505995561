import { useState } from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../layouts/Layout'
import { GenericTable } from '../components'
import CircularProgress from '@material-ui/core/CircularProgress'
import getProfileQuery from '../gql/queries/getProfile'
import ProfileDetail from '../components/ProfileDetail'
import { useApolloClient } from '@apollo/client'
import { ProfileUser } from '../types'
import UserPagesNavigation from '../components/UserPagesNavigation'
import { UserPagesTitles } from '../enums'
import getReferralsByUserId from '../gql/queries/getReferralsByUserId'
import { formatDate } from '../Utils'
import { Typography } from '@material-ui/core'
import { GetProfileQuery, GetProfileQueryVariables, GetReferralsByUserIdQuery, GetReferralsByUserIdQueryVariables } from '../gql/__generated__'
import useFallibleEffect from '../utils/fallibleEffect'

type Props = {}

export default function ReferralsPage(_props: Props) {
  const profileId = useParams().profileId!
  const client = useApolloClient()

  const [referrals, setReferrals] = useState([] as GetReferralsByUserIdQuery['getReferralsByUserId'])
  const [isLoaded, setIsLoaded] = useState(false)
  const [user, setUser] = useState<ProfileUser | undefined>()

  const getProfile = async (id: string) => {
    const result = await client.query<GetProfileQuery, GetProfileQueryVariables>({
      query: getProfileQuery,
      variables: {
        id
      }
    })
    return result.data.getProfileFixed
  }

  const getReferrals = async () => {
    const result = await client.query<GetReferralsByUserIdQuery, GetReferralsByUserIdQueryVariables>({
      query: getReferralsByUserId,
      variables: { userId: user!.id }
    })
    return result.data.getReferralsByUserId
  }

  const componentDidMount = async () => {
    const profile = await getProfile(profileId)
    setUser(profile.user)
  }
  const onUpdate = async () => {
    const referrals = await getReferrals()
    setReferrals(referrals)
  }
  useFallibleEffect(componentDidMount, setIsLoaded, [])
  useFallibleEffect(onUpdate, setIsLoaded, [user])

  const columns = [
    {
      Header: 'id',
      accessor: 'id'
    },
    {
      Header: 'Referral createdAt',
      accessor: (item: any) => formatDate(item.referee.createdAt)
    },
    {
      Header: 'hadCall',
      accessor: (item: any) =>  item.referee.hadCall.toString()
    },
    {
      Header: 'rewardedAt',
      accessor: (item: any) =>  formatDate(item.referee.rewardedAt)
    },
    {
      Header: 'User',
      accessor: (item: any) => `${item.firstName} ${item.lastName}`,
      link: (item: any) => `/profile/${item.students[0].id}`
    },
  ]

  if (!isLoaded) {
    return (
      <Layout>
        <CircularProgress size={20} thickness={5} />
      </Layout>
    )
  }

  return (
    <Layout>
      <Typography variant="h4">Profile detail - list of referrals</Typography>
      <UserPagesNavigation profileId={profileId} title={UserPagesTitles.Referrals} />
      <div>
        <ProfileDetail user={user!} profileId={profileId}/>
      </div>
      <div>
        <GenericTable title={`List of users referred by ${user!.firstName ?? user!.id}`} data={referrals} columns={columns}/>
      </div>
    </Layout>
  )
}
