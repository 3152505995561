import gql from 'graphql-tag'

export default gql`
  query getReferralsByUserId($userId: String!) {
    getReferralsByUserId(userId: $userId) {
      id
      firstName
      lastName
      displayName
      students {
        id
      }
      referee {
        createdAt
        hadCall
        rewardedAt
      }
    }
  }
`
