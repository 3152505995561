import gql from 'graphql-tag'

export default gql`
  query getRating($id: String!) {
    getRating(id: $id) {
      id
      ratingTotal
      givingProfileId
      ratedProfileId
      createdAt
      comment
      visible
      ignored
    }
  }
`
