import { FieldProps, getIn } from 'formik'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'
import React from 'react'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'
import FormHelperText from '@material-ui/core/FormHelperText'

type FormikDatePickerProps = FieldProps

const FormikDatePicker = ({
  // Formik field props
  // We prevent "onChange" to passed down to DatePicker. Formik needs custom connection here.
  field: { name, onChange, value, ...fieldProps },
  form: { touched, errors, setFieldValue },
  // Datepicker props
  ...props
}: FormikDatePickerProps) => {
  const fieldTouched = getIn(touched, name)
  const error = getIn(errors, name)
  const formattedDate = moment().format('DD/MM/YYYY')

  return (
    <React.Fragment>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          error={Boolean(fieldTouched && error)}
          fullWidth
          margin='normal'
          // openTo='year'
          format='dd/MM/yyyy'
          placeholder={formattedDate}
          // views={['year', 'month', 'day']}
          // variant='outlined'
          disableFuture
          // Formik needs custom connection here, onChange doesn't accept DateTime as parameter
          onChange={value => setFieldValue(name, value)}
          // Empty string would cause "Unknown" displayed
          value={value || null}
          {...fieldProps}
          {...props}
        />
      </MuiPickersUtilsProvider>
      {fieldTouched && error && (
        <FormHelperText error={true} style={{ marginLeft: '15px' }}>
          {error}
        </FormHelperText>
      )}
    </React.Fragment>
  )
}

export { FormikDatePicker }
