import gql from 'graphql-tag'

export default gql`
  query paymentStats($dateRangeInput: DateRangeInput!) {
    adminPaymentStats(dateRangeInput: $dateRangeInput) {
      date
      amount
      paymentType
      blabuState
    }
  }
`
