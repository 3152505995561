import gql from 'graphql-tag'

export default gql`
  query adminGetWalletBalance($userIds: [ String! ]!) {
    adminGetWalletBalances(userIds: $userIds) {
      balance
      userId
    }
  }
`
