import gql from 'graphql-tag'
import TutorUpdateFragment from '../fragments/tutorUpdateFragment'

export default gql`
  mutation TutorUpdateMutation($tutor: AdminTutorInput!, $image: Upload) {
    adminUpdateTutor(tutor: $tutor, image: $image) {
      ...TutorUpdateFragment
    }
  }
  ${TutorUpdateFragment}
`
