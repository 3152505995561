import { useState } from 'react'
import { useParams } from 'react-router-dom'
import JSONPretty from 'react-json-pretty'
import Layout from '../layouts/Layout'
import adminGetTransactions from '../gql/queries/adminGetTransactions'
import addPromoCreditMutation from '../gql/mutations/addPromoCredit'
import { Alert, GenericTable } from '../components'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import getProfile from '../gql/queries/getProfile'
import getBalance from '../gql/queries/getWallets'
import ProfileDetail from '../components/ProfileDetail'
import { GenericForm } from '../components/GenericForm'
import CreditFormSchema, { initialValues } from './formSchemas/CreditFormSchema'
import TutorPayoutSchema, { initialValues as tutorPayoutValues } from './formSchemas/TutorPayoutSchema'
import { formatDate, isStudent } from '../Utils'
import { useApolloClient } from '@apollo/client'
import NotificationService, { MessageType } from '../services/NotificationService'
import { ProfileUser } from '../types'
import { UserPagesTitles } from '../enums'
import UserPagesNavigation from '../components/UserPagesNavigation'
import CancelCreditSchema from './formSchemas/CancelCreditSchema'
import cancelGiftUserMutation from '../gql/mutations/cancelGiftUser'
import adminFindAllPaymentsByProfileId from '../gql/queries/adminFindAllPaymentsByProfileId'
import { Typography } from '@material-ui/core'
import { useQuery } from '@apollo/client'
import { AddPromoCreditMutation, AddPromoCreditMutationVariables, AdminFindAllPaymentsByProfileIdQuery, AdminFindAllPaymentsByProfileIdQueryVariables, AdminGetTransactionsQuery, AdminGetTransactionsQueryVariables, CancelGiftUserMutation, CancelGiftUserMutationVariables, GetProfileQuery, GetProfileQueryVariables, GetWalletsQuery, GetWalletsQueryVariables, TransactionType } from '../gql/__generated__'
import useFallibleEffect from '../utils/fallibleEffect'

type Props = {}

export default function WalletDetailPage(_props: Props) {
  const profileId = useParams().profileId!
  const client = useApolloClient()

  const [user, setUser] = useState<ProfileUser | undefined>()
  const [payments, setPayments] = useState([] as AdminFindAllPaymentsByProfileIdQuery['adminFindAllPaymentsByProfileId'])
  const [wallets, setWallets] = useState([] as GetWalletsQuery['getWallets'])
  const [isLoaded, setIsLoaded] = useState(false)

  const getPayments = async () => {
    const result = await client.query<AdminFindAllPaymentsByProfileIdQuery, AdminFindAllPaymentsByProfileIdQueryVariables>({
      query: adminFindAllPaymentsByProfileId,
      variables: {
        profileId,
      },
    })
    return result.data.adminFindAllPaymentsByProfileId
  }

  const getWallets = async () => {
    const result = await client.query<GetWalletsQuery, GetWalletsQueryVariables>({
      query: getBalance,
      variables: {
        userId: user!.id,
      },
    })
    return result.data.getWallets
  }

  const handleSubmitGift = async (values: any) => {
    await client.mutate<AddPromoCreditMutation, AddPromoCreditMutationVariables>({
      mutation: addPromoCreditMutation,
      variables: {
        data: {
          userId: user!.id,
          profileId: profileId,
          amount: Number(values.amount),
          expiresAt: values.expiresAt,
          transactionType: isStudent(profileId) ? TransactionType.StudentGift : TransactionType.TutorGift,
          comment: values.comment ? values.comment : null,
        },
      },
    })
    NotificationService.show(MessageType.success, 'User gifted successfully')
    window.location.reload()
  }

  const handleSubmitCancel = async (values: any) => {
    await client.mutate<CancelGiftUserMutation, CancelGiftUserMutationVariables>({
      mutation: cancelGiftUserMutation,
      variables: {
        data: {
          userId: user!.id,
          profileId: profileId,
          amount: Number(values.amount),
          comment: values.comment ? values.comment : null,
        },
      },
    })
    NotificationService.show(MessageType.success, 'User credit canceled successfully')
    window.location.reload()
  }

  const handleTutorPayout = async (values: any) => {
    NotificationService.show(MessageType.success, 'Tutor payout successful')
    window.location.reload()
  }

  const getInitialValues = () => initialValues

  const getPayoutInitialValues = () => tutorPayoutValues

  const componentDidMount = async () => {
    const result = await client.query<GetProfileQuery, GetProfileQueryVariables>({
      query: getProfile,
      variables: {
        id: profileId,
      },
    })
    setUser(result.data.getProfileFixed.user)

    const payments = await getPayments()
    setPayments(payments)
  }
  useFallibleEffect(componentDidMount, setIsLoaded, [])
  useFallibleEffect(() => getWallets().then(w => setWallets(w)), setIsLoaded, [user])

  const transactionsColumns = [
    {
      Header: 'amount',
      accessor: (item: any) => `${item.amount} $`,
    },
    {
      Header: 'type',
      accessor: 'type',
    },
    {
      Header: 'createdAt',
      accessor: (item: any) => {
        return formatDate(item.createdAt)
      },
    },
    {
      Header: 'expiresAt',
      accessor: (item: any) => {
        return formatDate(item.expiresAt)
      },
    },
    {
      Header: 'sourceId',
      accessor: 'sourceId',
    },
    {
      Header: 'comment',
      accessor: 'comment',
    },
  ]

  const paymentsColumns = [
    {
      Header: 'amount',
      accessor: (item: any) => `${item.amount} $`,
    },
    {
      Header: 'type',
      accessor: (item: any) => {
        return item.type.replace('Payment', '')
      },
    },
    {
      Header: 'state',
      accessor: (item: any) => {
        return item.blabuState.toLowerCase()
      },
    },
    {
      Header: 'createdAt',
      accessor: (item: any) => {
        return formatDate(item.createdAt)
      },
    },
    {
      Header: 'data',
      accessor: (item: any) => {
        let data
        if (item.type === 'CsobPayment') {
          data = item.csobGatewayData
        } else if (item.type === 'PaypalPayment') {
          data = item.paypalGatewayData
        } else if (item.type === 'SodexoPayment') {
          data = item.sodexoGatewayData
        } else if (item.type === 'PayUPayment') {
          data = item.payuGatewayData
        } else if (item.type === 'GalleryBetaPayment') {
          data = item.dbGatewayData
        } else if (item.type === 'MolliePayment') {
          data = item.mollieGatewayData
        }

        return <JSONPretty style={{ overflow: 'auto' }} data={data}></JSONPretty>
      },
    },
  ]

  const { data, loading, error } = useQuery<AdminGetTransactionsQuery, AdminGetTransactionsQueryVariables>(adminGetTransactions, { variables: { profileId } })
  if (!isLoaded) {
    return null
  }

  return (
    <Layout>
      <Typography variant='h4'>Profile detail - list of transactions</Typography>
      <UserPagesNavigation title={UserPagesTitles.WalletDetail} profileId={profileId} />
      <ProfileDetail user={user!} wallets={wallets} profileId={profileId} />
      <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' spacing={3}>
        <Grid item xs={10}>
          <GenericTable title='Payments' data={payments} columns={paymentsColumns} />
          <div>
            {loading && <CircularProgress size={20} thickness={5} />}
            {!loading && error && <Alert>{JSON.stringify(error)}</Alert>}
            {!loading && data && (
              <div style={{ marginTop: 25 }}>
                <GenericTable
                  title='Transactions'
                  data={data.adminGetTransactions}
                  columns={transactionsColumns}
                />
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={2}>
          <GenericForm
            getInitialValues={getInitialValues}
            schema={CreditFormSchema}
            onSubmit={handleSubmitGift}
          />
          <GenericForm
            getInitialValues={getInitialValues}
            schema={CancelCreditSchema}
            onSubmit={handleSubmitCancel}
          />
          <GenericForm
            getInitialValues={getPayoutInitialValues}
            schema={TutorPayoutSchema}
            onSubmit={handleTutorPayout}
          />
        </Grid>
      </Grid>
    </Layout>
  )
}
