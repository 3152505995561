import gql from 'graphql-tag'

export default gql`
  query adminGetConversation($id: String!) {
    adminGetConversation(id: $id, pageInfo: { first: 200, offset: 0 }) {
      id
      messages {
        content
        sent
        fromUserId
        toUserId
        read
        roomId
      }
      members {
        userId
      }
    }
  }
`
