import { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import Layout from '../layouts/Layout'
import adminFindRooms from '../gql/queries/adminFindRooms'
import getCompositionFile from '../gql/queries/getCompositionSid'
import { Alert, GenericTable } from '../components'
import CircularProgress from '@material-ui/core/CircularProgress'
import getProfile from '../gql/queries/getProfile'
import ProfileDetail from '../components/ProfileDetail'
import { formatDate } from '../Utils'
import NotificationService from '../services/NotificationService'
import SendNewPassword from '../components/SendNewPassword'
import UserPagesNavigation from '../components/UserPagesNavigation'
import { UserPagesTitles } from '../enums'
import { IconButton } from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import Typography from '@material-ui/core/Typography'
import { useQuery } from '@apollo/client'
import { AdminFindRoomsQuery, AdminFindRoomsQueryVariables, GetCompositionFileQuery, GetCompositionFileQueryVariables, GetProfileQuery, GetProfileQueryVariables } from '../gql/__generated__'
import { User } from '../types'
import usePagination from '../utils/pagination'
import useFallibleEffect from '../utils/fallibleEffect'

const getParticipant = (room: any, role: string) => {
  return room.participants.find((participant: any) => participant.role === role)
}

const tutorRow = {
  Header: 'Tutor',
  accessor: (item: any) => {
    const participant = getParticipant(item, 'Tutor')
    return participant
      ? `${participant.profile.user.firstName} ${participant.profile.user.lastName}`
      : 'Tutor not found'
  },
  link: (item: any) => {
    const participant = getParticipant(item, 'Tutor')
    return participant ? `/profile/${participant.profileId}` : undefined
  },
}

const studentRow = {
  Header: 'Student',
  accessor: (item: any) => {
    const participant = getParticipant(item, 'Student')
    return participant
      ? `${participant.profile.user.firstName} ${participant.profile.user.lastName}`
      : 'Student not found'
  },
  link: (item: any) => {
    const participant = getParticipant(item, 'Student')
    return participant ? `/profile/${participant.profileId}` : undefined
  },
}

type Props = {}

export default function ProfileDetailPage(_props: Props) {
  const profileId = useParams().profileId!
  const client = useApolloClient()
  const navigate = useNavigate()
  
  const [rooms, setRooms] = useState([] as AdminFindRoomsQuery['adminFindRooms'])
  const [isLoaded, setIsLoaded] = useState(false)
  const { addToCount, pagination } = usePagination({
    setIsLoaded,
    onUpdate: async (r, p) => { await onUpdate(r, p) }
  })

  const onUpdate = async (rowsPerPage = pagination.rowsPerPage, page = pagination.page) => {
    const rooms = await findRooms(rowsPerPage, rowsPerPage * page)
    setRooms(rooms)
    addToCount(rooms.length)
  }
  useFallibleEffect(onUpdate, setIsLoaded, [])

  const getCompositionsFile = async (roomId: string) => {
    const result = await client.query<GetCompositionFileQuery, GetCompositionFileQueryVariables>({
      query: getCompositionFile,
      variables: {
        roomId,
      },
    })
    return result.data.getCompositionFile
  }

  const findRooms = async (first: number, offset: number) => {
    const result = await client.query<AdminFindRoomsQuery, AdminFindRoomsQueryVariables>({
      query: adminFindRooms,
      variables: {
        profileId,
        pageInfo: {
          first,
          offset,
        },
      },
    })

    return result.data.adminFindRooms
  }

  const handleOnComplete = (data: any) => {
    if (!data || !data?.getProfileFixed) {
      navigate('/')
    }
  }

  const handleClick = async (roomId: string) => {
    const compositionFile = await getCompositionsFile(roomId)

    if (compositionFile) {
      window.location.href = compositionFile
    } else {
      NotificationService.showError('No composition exists for this room')
    }
  }

  const columns: any[] = [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: 'duration(s)',
      accessor: 'duration',
    },
    {
      Header: 'started',
      accessor: (room: any) => {
        return formatDate(room.started)
      },
    },
    {
      Header: 'ended',
      accessor: (room: any) => formatDate(room.ended),
    },
    {
      Header: 'actual duration',
      accessor: 'actualDuration',
    },
    {
      Header: 'cancelled',
      accessor: (room: any) => (room.cancelled ? 'yes' : ''),
    },
    {
      Header: 'price Charged',
      accessor: 'priceCharged',
    },
    {
      Header: 'download',
      accessor: (room: any) => {
        if (room.video && room.video.status === 'Queued') {
          return (
            <IconButton onClick={() => handleClick(room.id)}>
              <DownloadIcon />
            </IconButton>
          )
        }
      },
    },
    {
      Header: 'package',
      accessor: (room: any) => room.lesson && room.lesson.package.name,
    },
    {
      Header: 'state',
      accessor: 'state',
    },
    {
      Header: 'meta',
      accessor: (room: any) => JSON.stringify(room.meta),
    },
  ]

  let profileType = 'tutor'
  if (profileId.startsWith('ST')) {
    profileType = 'student'
    columns.push(tutorRow)
  } else {
    columns.push(studentRow)
  }

  const { data, loading, error } = useQuery<GetProfileQuery, GetProfileQueryVariables>(getProfile, { variables: { id: profileId }, onCompleted: handleOnComplete })
  return (
    <Layout>
      <Typography variant='h4'>{profileType === 'tutor' ? 'Tutor' : 'Student'} detail - list of calls</Typography>
      <UserPagesNavigation profileId={profileId} title={UserPagesTitles.Calls} />
      <div>
        {loading && <CircularProgress size={20} thickness={5} />}
        {!loading && error && <Alert>{JSON.stringify(error)}</Alert>}
        {!loading && !error && data && data?.getProfileFixed && (
          <div>
            <ProfileDetail user={data.getProfileFixed.user} profileId={profileId} />
            <SendNewPassword user={data.getProfileFixed.user as User} />
          </div>
        )}
      </div>
      {isLoaded ? (
        <GenericTable data={rooms} columns={columns} pagination={pagination} />
      ) : (
        <CircularProgress size={20} thickness={5} />
      )}
    </Layout>
  )
}
