import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Tooltip, BarChart, Bar, Legend, XAxis, YAxis } from 'recharts'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import countCallsQuery from '../gql/queries/statsCountCalls'
import countUnsuccessfulCallsQuery from '../gql/queries/statsCountUnsuccessfulCalls'
import SimpleSelect from '../components/SimpleSelect'
import moment from 'moment'
import MenuItem from '@material-ui/core/MenuItem'
import { generateDateRanges } from '../Utils'
import { DailyCount, DateRange } from '../types'
import NotificationService from '../services/NotificationService'
import { getApolloContext } from '@apollo/client'

type Props = {}

interface State {
  isLoaded: boolean
  countCalls: DailyCount[]
  countUnsuccessfulCalls: DailyCount[]
  ranges: DateRange[]
  selectedRange: string
}

class CallsStats extends React.Component<Props, State> {
  state: State = {
    isLoaded: false,
    countCalls: [],
    countUnsuccessfulCalls: [],
    ranges: [],
    selectedRange: '2019-05-03',
  }
  static contextType = getApolloContext()
  context!: React.ContextType<ReturnType<typeof getApolloContext>>

  count = async (from: string, to: string) => {
    const callsPromise = this.context.client!.query({
      query: countCallsQuery,
      variables: {
        dateRangeInput: {
          from,
          to
        }
      }
    })
    const UnsuccessfulCallsPromise = this.context.client!.query({
      query: countUnsuccessfulCallsQuery,
      variables: {
        dateRangeInput: {
          from,
          to
        }
      }
    })
    const [ countCalls, countUnsuccessfulCalls ] = await Promise.all([ callsPromise, UnsuccessfulCallsPromise])
    this.setState({
      countCalls: countCalls.data.statsCountCalls,
      countUnsuccessfulCalls: countUnsuccessfulCalls.data.statsCountUnsuccessfulCalls,
      isLoaded: true
    })
  }

  componentDidMount = async () => {
    const ranges = generateDateRanges(4)
    await this.setState({ranges, selectedRange: ranges[0].from})
    try {
      await this.count(ranges[0].from, ranges[0].to)
    } catch (err) {
      NotificationService.showError('Cannot count calls')
    }
  }

  generateData = () => {
    const data = []
    const { countCalls, countUnsuccessfulCalls } = this.state

    for (let i = 0; i < countCalls.length; i++) {
      data.push({ name: countCalls[i].date, calls: countCalls[i].count, unsuccessful: countUnsuccessfulCalls[i].count })
    }

    return data
  }

  handleChange = async (e: any) => {
    await this.setState({ selectedRange: e.target.value })
    const to = moment(this.state.selectedRange).add(6, 'days').format('YYYY-MM-DD')
    await this.count(this.state.selectedRange, to)
  }

  render() {
    if (!this.state.isLoaded) {
      return (
          <CircularProgress size={20} thickness={5} />
      )
    }
    const data = this.generateData()

    const menuItems = () => {
      return this.state.ranges.map(r => {
        return (
          <MenuItem key={r.from} value={ r.from }>
            { `${r.from} - ${r.to}` }
          </MenuItem>
        )
      })
    }

    return (
        <Card>
          <CardContent>
            <Typography variant="h4">Calls</Typography>
            <SimpleSelect handleChange={this.handleChange} value={this.state.selectedRange} menuItems={menuItems()}/>
            <BarChart width={800} height={350} data={data}>
              <XAxis dataKey="name"/>
              <YAxis/>
              <Tooltip/>
              <Legend/>
              <Bar dataKey="calls" fill="#80bd00"/>
              <Bar dataKey="unsuccessful" fill="#ff313b"/>
            </BarChart>
            <Typography variant="body1">
              Note: Calls are considered to be successful if they took longer than 1 minute.
            </Typography>
          </CardContent>
        </Card>
    )
  }
}

export default CallsStats
