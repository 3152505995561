import { useState } from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../layouts/Layout'
import { GenericTable } from '../components'
import CircularProgress from '@material-ui/core/CircularProgress'
import getProfileQuery from '../gql/queries/getProfile'
import adminGetUserStatusHistoryQuery from '../gql/queries/adminGetUserStatusHistory'
import ProfileDetail from '../components/ProfileDetail'
import { formatDate } from '../Utils'
import { useApolloClient } from '@apollo/client'
import { ProfileUser } from '../types'
import UserPagesNavigation from '../components/UserPagesNavigation'
import { UserPagesTitles } from '../enums'
import { Typography } from '@material-ui/core'
import usePagination from '../utils/pagination'
import { AdminGetUserStatusHistoryQuery, AdminGetUserStatusHistoryQueryVariables, GetProfileQuery, GetProfileQueryVariables } from '../gql/__generated__'
import useFallibleEffect from '../utils/fallibleEffect'

type Props = {}

export default function OnlineStatusHistoryPage(_props: Props) {
  const profileId = useParams().profileId!
  const client = useApolloClient()

  const [user, setUser] = useState<ProfileUser | undefined>()
  const [historyRecords, setHistoryRecords] = useState([] as AdminGetUserStatusHistoryQuery['adminGetUserStatusHistory'])
  const [isLoaded, setIsLoaded] = useState(false)

  const { pagination, addToCount } = usePagination({
    setIsLoaded,
    onUpdate: (r, p) => getUserStatusHistory(r, p),
    rowsPerPageOptions: [30, 50, 100]
  })

  const getProfile = async () => {
    const result = await client.query<GetProfileQuery, GetProfileQueryVariables>({
      query: getProfileQuery,
      variables: {
        id: profileId
      }
    })
    setUser(result.data.getProfileFixed.user)
  }

  const getUserStatusHistory = async (rowsPerPage: number, page: number) => {
    const result = await client.query<AdminGetUserStatusHistoryQuery, AdminGetUserStatusHistoryQueryVariables>({
      query: adminGetUserStatusHistoryQuery,
      variables: {
        userId: user!.id,
        pageInfo: {
          first: rowsPerPage,
          offset: page * rowsPerPage
        }
      }
    })
    setHistoryRecords(result.data.adminGetUserStatusHistory)
    addToCount(result.data.adminGetUserStatusHistory.length)
  }

  useFallibleEffect(getProfile, setIsLoaded, [])
  useFallibleEffect(() => getUserStatusHistory(pagination.rowsPerPage, 0), setIsLoaded, [user])

  const columns = [
    {
      Header: 'Status',
      accessor: 'status'
    },
    {
      Header: 'Date and time',
      accessor: (item: any) => {
        return formatDate(item.createdAt)
      }
    }
  ]

  if (!isLoaded) {
    return (
      <Layout>
        <CircularProgress size={20} thickness={5} />
      </Layout>
    )
  }

  return (
    <Layout>
      <Typography variant="h4">Online status history</Typography>
      <UserPagesNavigation profileId={profileId} title={UserPagesTitles.StatusHistory}/>
      <ProfileDetail user={user!} profileId={profileId}/>
      <GenericTable data={historyRecords} pagination={pagination} columns={columns}/>
    </Layout>
  )
}
