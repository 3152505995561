import React from 'react'
import { Field, FormikConfig, FieldProps, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { StyleRulesCallback, withStyles, WithStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Select from 'react-select'
import MenuItem from '@material-ui/core/MenuItem'

import { Alert, FormikField, FormMessage } from './'
import { errorToMessage } from '../api/GraphQLUtils'
import { Topic } from '../types'
import { Grid } from '@material-ui/core'
import { get } from 'lodash'

interface getInitialValuesFn<T> {
  (): Promise<T>
}

export interface FormSchema {
  /** From form state to real state */
  denormalize?: (item: any) => any
  fieldsets: Array<{
    title?: string
    size?: number
    fields: Array<{
      accessor: string
      component?: string | React.ComponentType<FieldProps<any>> | React.ComponentType<void>
      label: string
      fieldProps?: {
        [prop: string]: any
      }
      default?: any
      hideWhen?: {
        accessor: string
        value: any
      }
    }>
  }>
  /** From real state to form state */
  normalize?: (item: any) => any
}

interface GenericFormProps<Values = any> extends WithStyles<typeof styles> {
  submitBtnTitle?: string
  getInitialValues: Values | getInitialValuesFn<Values>
  schema: FormSchema
  onSubmit: (values: Values, actions: FormikHelpers<Values>) => Promise<Values>
  validationSchema?: FormikConfig<any>['validationSchema']
}

interface GenericFormState<Values = any> {
  initialValues?: Values
  loading: boolean
  error?: string
  selects: any[]
}

const styles: StyleRulesCallback<any, any> = (theme) => ({
  fieldset: {
    marginBottom: theme.spacing(3),
  },
  select: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
})

class GenericFormImpl extends React.Component<GenericFormProps, GenericFormState> {
  state: GenericFormState = {
    loading: true,
    selects: [],
  }

  componentDidMount(): void {
    const { getInitialValues, schema } = this.props
    const { normalize } = schema
    const $initialValues = getInitialValues()

    // Async initial values
    // E.g. used in update forms, where we read data from API first
    if ($initialValues.then) {
      $initialValues
        .then((initialValues: any) => {
          this.setState({
            loading: false,
            initialValues: normalize ? normalize(initialValues) : initialValues,
          })
        })
        .catch(() => {
          this.setState({
            loading: false,
            error: 'An error occurred when loading form',
          })
        })
      // Sync initial values
      // E.g. used in create forms where we have empty initial state
    } else {
      const initialValues = $initialValues
      this.setState({
        loading: false,
        initialValues: normalize ? normalize(initialValues) : initialValues,
      })
    }
  }

  handleSubmit = (values: any, actions: FormikHelpers<any>) => {
    const { schema, onSubmit } = this.props
    const { denormalize } = schema
    const denormalizedValues = denormalize ? denormalize(values) : values

    actions.setSubmitting(true)
    onSubmit(denormalizedValues, actions)
      .then((result) => {
        actions.setSubmitting(false)
        if (result) {
          actions.resetForm(this.props.getInitialValues())
        }
      })
      .catch((error) => {
        const message = errorToMessage(error)
        actions.setSubmitting(false)
        actions.setStatus(message)
      })
  }

  render() {
    const { classes, schema, submitBtnTitle, validationSchema } = this.props
    const { loading, error, initialValues } = this.state
    const { fieldsets } = schema
    const buttonDefaultText = submitBtnTitle ? submitBtnTitle : 'Save'

    return (
      <div className='mb-5'>
        {loading && <CircularProgress size={20} thickness={5} />}
        {!loading && error && <Alert>{JSON.stringify(error)}</Alert>}
        {!loading && initialValues && (
          <Formik
            initialValues={initialValues}
            onSubmit={this.handleSubmit}
            validationSchema={validationSchema}
          >
          {(formikBag: FormikProps<any>) => {
            const { status, isSubmitting } = formikBag

            const submitButton = (
              <p>
                <Button color='primary' disabled={isSubmitting} type='submit' variant='contained'>
                  &nbsp;{isSubmitting ? 'Saving...' : buttonDefaultText}&nbsp;
                </Button>
              </p>
            )
            return (
              <Form>
                <Grid
                  container
                  direction='row'
                  alignItems='flex-start'
                  spacing={3}
                  key='grid'
                >
                  {status && <FormMessage>{status}</FormMessage>}
                  {fieldsets.map((fieldset, s) => (
                    <Grid key={s} item xs={(fieldset.size ? fieldset.size : true) as any}>
                      <Card className={classes.fieldset} style={{ overflow: 'visible' }}>
                        <CardContent>
                          {fieldset.title && <Typography variant='h5'>{fieldset.title}</Typography>}
                          {fieldset.fields.map((field, f) => {
                            const fieldProps = field.fieldProps || {}
                            const fieldIsHidden = field.hideWhen && get(formikBag.values, field.hideWhen.accessor) === field.hideWhen.value

                            if (fieldIsHidden) {
                              return null
                            }

                            if (fieldProps.multiple) {
                              const Option = (props: any) => (
                                <MenuItem
                                  ref={props.innerRef}
                                  selected={props.isFocused}
                                  component='div'
                                  {...props.innerProps}
                                >
                                  {props.children}
                                </MenuItem>
                              )
                              const comps = {
                                Option,
                              }
                              return (
                                <div className={classes.select} key={f}>
                                  <Select
                                    // label={field.label}
                                    placeholder={field.label}
                                    components={comps}
                                    onChange={(evt: any) => {
                                      formikBag.setFieldValue(field.accessor, evt && evt.map((e: any) => e.value))
                                      this.setState({ selects: evt })
                                      this.setState({ initialValues: { ...initialValues, topics: [] } })
                                    }}
                                    styles={{
                                      menu: (styles: any) => {
                                        return { ...styles, zIndex: 1000 }
                                      },
                                    }}
                                    options={fieldProps.selectOptions}
                                    value={
                                      (this.state.selects && this.state.selects.length && this.state.selects) ||
                                      (this.state.initialValues.topics &&
                                        this.state.initialValues.topics.length &&
                                        this.state.initialValues.topics.map((topic: Topic) => {
                                          return {
                                            value: topic.id,
                                            label: topic.name,
                                          }
                                        }))
                                    }
                                    isMulti={true}
                                    {...fieldProps}
                                  />
                                </div>
                              )
                            }
                            return (
                              <Field
                                key={f}
                                name={field.accessor}
                                component={field.component ?? FormikField}
                                label={field.label}
                                {...fieldProps}
                              />
                            )
                          })}
                          {fieldsets.length === 1 && submitButton}
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
                {fieldsets.length > 1 && submitButton}
              </Form>
            )
          }}
          </Formik>
        )}
      </div>
    )
  }
}

const GenericForm = withStyles(styles)(GenericFormImpl)

export { GenericForm }
