import { useState } from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../layouts/Layout'
import { GenericTable } from '../components'
import CircularProgress from '@material-ui/core/CircularProgress'
import getProfileQuery from '../gql/queries/getProfile'
import ProfileDetail from '../components/ProfileDetail'
import { formatDate } from '../Utils'
import { useApolloClient } from '@apollo/client'
import { ProfileUser } from '../types'
import NotificationService from '../services/NotificationService'
import UserPagesNavigation from '../components/UserPagesNavigation'
import { UserPagesTitles } from '../enums'
import adminGetActiveSubscriptionsQuery from '../gql/queries/adminGetActiveSubscriptions'
import { IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import adminDeleteSubscriptionMutation from '../gql/mutations/adminDeleteSubscription'
import Typography from '@material-ui/core/Typography'
import { AdminDeleteSubscriptionMutation, AdminDeleteSubscriptionMutationVariables, AdminGetActiveSubscriptionsQuery, AdminGetActiveSubscriptionsQueryVariables, GetProfileQuery, GetProfileQueryVariables } from '../gql/__generated__'
import useFallibleEffect, { wrapFallible } from '../utils/fallibleEffect'

type Props = {}

export default function SubscriptionsPage(_props: Props) {
  const profileId = useParams().profileId!
  const client = useApolloClient()

  const [user, setUser] = useState<ProfileUser | undefined>()
  const [activeSubscriptions, setActiveSubscriptions] = useState([] as AdminGetActiveSubscriptionsQuery['adminGetActiveSubscriptions'])
  const [isLoaded, setIsLoaded] = useState(false)

  const getProfile = async () => {
    const result = await client.query<GetProfileQuery, GetProfileQueryVariables>({
      query: getProfileQuery,
      variables: {
        id: profileId
      }
    })
    setUser(result.data.getProfileFixed.user)
  }

  const getActiveSubscriptions = async () => {
    const result = await client.query<AdminGetActiveSubscriptionsQuery, AdminGetActiveSubscriptionsQueryVariables>({
      query: adminGetActiveSubscriptionsQuery,
      variables: {
        userId: user!.id
      }
    })
    setActiveSubscriptions(result.data.adminGetActiveSubscriptions)
  }

  const handleDelete = wrapFallible(async (subscriptionId: string) => {
    await client.mutate<AdminDeleteSubscriptionMutation, AdminDeleteSubscriptionMutationVariables>({
      mutation: adminDeleteSubscriptionMutation,
      variables: {
        id: subscriptionId
      }
    })
    await getActiveSubscriptions()
    NotificationService.showSuccess('Subscription deleted')
  }, setIsLoaded)

  useFallibleEffect(getProfile, setIsLoaded, [])
  useFallibleEffect(getActiveSubscriptions, setIsLoaded, [user])
  
  const columns = [
    {
      Header: 'Subscribed To',
      accessor: (item: any) => `${item.profile.user.firstName} ${item.profile.user.lastName}`,
      link: (item: any) => `/profile/${item.subscribedToProfileId}`
    },
    {
      Header: 'createdAt',
      accessor: (item: any) => formatDate(item.createdAt)
    },
    {
      Header: 'Delete',
      accessor: (item: any) => {
        return (
          <IconButton onClick={() => handleDelete(item.id)}>
            <DeleteIcon />
          </IconButton>
        )
      }
    }
  ]

  if (!isLoaded) {
    return (
      <Layout>
        <CircularProgress size={20} thickness={5} />
      </Layout>
    )
  }

  return (
    <Layout>
      <Typography variant="h4">{UserPagesTitles.TutorNotifications}</Typography>
      <UserPagesNavigation profileId={profileId} title={UserPagesTitles.TutorNotifications}/>
      <ProfileDetail user={user!} profileId={profileId}/>
      <GenericTable data={activeSubscriptions} columns={columns}/>
    </Layout>
  )
}
