import gql from 'graphql-tag'

export default gql`
  fragment TutorUpdateFragment on Tutor {
    id
    language
    motto
    introduction
    rating
    user {
      id
      firstName
      lastName
      countryCode
      middleName
      gender
      birthday
      timezone
      image
      credentials {
        identifier
      }
    }
  }
`
