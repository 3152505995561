import { useState } from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../layouts/Layout'
import ProfileDetail from '../components/ProfileDetail'
import { useApolloClient } from '@apollo/client'
import UserPagesNavigation from '../components/UserPagesNavigation'
import { UserPagesTitles } from '../enums'
import TutorPackagesContent from '../components/TutorPackagesContent'
import { isStudent } from '../Utils'
import StudentPackagesContent from '../components/StudentPackagesContent'
import { Typography } from '@material-ui/core'
import getProfileQuery from '../gql/queries/getProfile'
import { GetProfileQuery, GetProfileQueryVariables } from '../gql/__generated__'
import CircularProgress from '@material-ui/core/CircularProgress'
import useFallibleEffect from '../utils/fallibleEffect'

type Props = {}

export default function PackagesPage(_props: Props) {
  const profileId = useParams().profileId!
  const client = useApolloClient()

  const [isLoaded, setIsLoaded] = useState(false)
  const [profile, setProfile] = useState<GetProfileQuery['getProfileFixed'] | undefined>()

  const getProfile = async (id: string) => {
    const result = await client.query<GetProfileQuery, GetProfileQueryVariables>({
      query: getProfileQuery,
      variables: {
        id
      }
    })
    return result.data.getProfileFixed
  }

  useFallibleEffect(() => getProfile(profileId).then(p => setProfile(p)), setIsLoaded, [])

  if (!isLoaded) {
    return <CircularProgress size={20} thickness={5} />
  }

  return (
    <Layout>
      <Typography variant="h4">Packages</Typography>
      <UserPagesNavigation title={UserPagesTitles.Packages} profileId={profileId} />
      <ProfileDetail user={profile!.user} profileId={profileId}/>
      {isStudent(profileId) ?
        <StudentPackagesContent profileId={profileId} userId={profile!.user.id} /> :
        <TutorPackagesContent profileId={profileId} />}
    </Layout>
  )
}
