import { useState } from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../layouts/Layout'
import getRatingsQuery from '../gql/queries/getRatings'
import { Alert, GenericTable } from '../components'
import CircularProgress from '@material-ui/core/CircularProgress'
import getProfile from '../gql/queries/getProfile'
import ProfileDetail from '../components/ProfileDetail'
import { formatDate } from '../Utils'
import { useApolloClient } from '@apollo/client'
import Button from '@material-ui/core/Button'
import setVisibilityMutation from '../gql/mutations/setVisibility'
import setRatingIgnoredMutation from '../gql/mutations/setRatingIgnored'
import NotificationService from '../services/NotificationService'
import { Rating } from '../types'
import UserPagesNavigation from '../components/UserPagesNavigation'
import { UserPagesTitles } from '../enums'
import { Typography } from '@material-ui/core'
import { useQuery } from '@apollo/client'
import { GetProfileQuery, GetProfileQueryVariables, GetRatingsQuery, GetRatingsQueryVariables, SetRatingIgnoredMutation, SetRatingIgnoredMutationVariables, SetVisibilityMutation, SetVisibilityMutationVariables } from '../gql/__generated__'
import useFallibleEffect from '../utils/fallibleEffect'

type Props = {}

export default function RatingsPage(_props: Props) {
  const profileId = useParams().profileId!
  const client = useApolloClient()

  const [ratings, setRatings] = useState([] as Rating[])
  const [isLoaded, setIsLoaded] = useState(false)

  const getRatings = async () => {
    setIsLoaded(false)
    const result = await client.query<GetRatingsQuery, GetRatingsQueryVariables>({
      query: getRatingsQuery,
      variables: { profileId }
    })
    setRatings(result.data.getRatings)
    setIsLoaded(true)
  }

  const setVisibility = async (id: string, visible: boolean) => {
    await client.mutate<SetVisibilityMutation, SetVisibilityMutationVariables>({
      mutation: setVisibilityMutation,
      variables: {
        id,
        visible
      }
    })
  }

  const setRatingIgnored = async (id: string, ignored: boolean) => {
    await client.mutate<SetRatingIgnoredMutation, SetRatingIgnoredMutationVariables>({
      mutation: setRatingIgnoredMutation,
      variables: {
        id,
        ignored
      }
    })
  }

  const handleClickVisible = async (id: string, visible: boolean) => {
    try {
      await setVisibility(id, visible)
      NotificationService.showSuccess('done')
      await getRatings()
    } catch (err: any) {
      NotificationService.showError(err.message)
    }
  }

  const handleClickIgnored = async (id: string, ignored: boolean) => {
    try {
      await setRatingIgnored(id, ignored)
      NotificationService.showSuccess('done')
      await getRatings()
    } catch (err: any) {
      NotificationService.showError(err.message)
    }
  }

  useFallibleEffect(getRatings, setIsLoaded, [])
  
  const columns = [
    {
      Header: 'ratingTotal',
      accessor: 'ratingTotal'
    },
    {
      Header: 'givingProfileId',
      accessor: 'givingProfileId',
      link: (item: any) => `/profile/${item.givingProfileId}`
    },
    {
      Header: 'createdAt',
      accessor: (item: any) => {
        return formatDate(item.createdAt)
      }
    },
    {
      Header: 'comment',
      accessor: 'comment'
    },
    {
      Header: 'visibility',
      accessor: (item: any) => {
        if (item.visible) {
          return <Button color='secondary' variant='contained' size='small' onClick={() => handleClickVisible(item.id, false)}>Hide</Button>
        }
        return <Button color='primary' variant='contained' size='small' onClick={() => handleClickVisible(item.id, true)}>Make visible</Button>
      }
    },
    {
      Header: 'ignored',
      accessor: (item: any) => {
        if (item.ignored) {
          return <Button color='primary' variant='contained' size='small' onClick={() => handleClickIgnored(item.id, false)}>Count in</Button>
        }
        return <Button color='secondary' variant='contained' size='small' onClick={() => handleClickIgnored(item.id, true)}>Ignore</Button>
      }
    },
    {
      Header: 'edit',
      accessor: (item: any) => 'Edit comment',
      link: (item: any) => `/rating/${item.id}`
    }
  ]

  const { data, loading, error } = useQuery<GetProfileQuery, GetProfileQueryVariables>(getProfile, { variables: { id: profileId } })
  if (!isLoaded) {
    return (
      <Layout>
        <CircularProgress size={20} thickness={5} />
      </Layout>
    )
  }

  return (
    <Layout>
      <Typography variant="h4">Profile detail - list of ratings</Typography>
      <UserPagesNavigation profileId={profileId} title={UserPagesTitles.Ratings} />
      <div>
        {loading && <CircularProgress size={20} thickness={5} />}
        {!loading && error && <Alert>{JSON.stringify(error)}</Alert>}
        {!loading && data && (
          <div>
            <ProfileDetail user={data.getProfileFixed.user} profileId={profileId} />
          </div>
        )}
      </div>
      <div>
        <GenericTable data={ratings} columns={columns} />
      </div>
    </Layout>
  )
}
