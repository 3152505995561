import React from 'react'
import SchoolIcon from '@material-ui/icons/School'
import CalendarIcon from '@material-ui/icons/Event'
import PersonIcon from '@material-ui/icons/Person'
import HomeIcon from '@material-ui/icons/Home'
import MusicIcon from '@material-ui/icons/MusicNote'
import OfferIcon from '@material-ui/icons/LocalOffer'
import MenuItem from '../../components/MenuItem'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import PackageIcon from '@material-ui/icons/ViewList'
import GroupIcon from '@material-ui/icons/Group'

const menuItems = (
  <div>
    <MenuItem route='/' title='Dashboard' icon={<HomeIcon />} />
    <MenuItem route='/tutors' title='Tutors' icon={<SchoolIcon />} />
    <MenuItem route='/tutor/create' title='Tutors' icon={<PlaylistAddIcon />} />
    <MenuItem route='/students' title='Students' icon={<PersonIcon />} />
    <MenuItem route='/topics' title='Topics' icon={<MusicIcon />} />
    <MenuItem route='/coupons' title='Coupons' icon={<OfferIcon />} />
    <MenuItem route='/packages/create' title='Packages' icon={<PackageIcon />} />
    <MenuItem route='/shifts' title='Shifts' icon={<CalendarIcon />} />
    <MenuItem route='/groups' title='Groups' icon={<GroupIcon />} />
  </div>
)

export default menuItems
