import { useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

import Layout from '../layouts/Layout'
import { GenericTable } from '../components'
import Button from '@material-ui/core/Button'
import getTutorsQuery from '../gql/queries/getTutors'
import setTutorActiveStateMutation from '../gql/mutations/setTutorActiveState'
import NotificationService from '../services/NotificationService'
import PhoneIcon from '@material-ui/icons/Phone'
import MoneyIcon from '@material-ui/icons/Money'
import StarIcon from '@material-ui/icons/Star'
import MessageIcon from '@material-ui/icons/Message'
import VideoIcon from '@material-ui/icons/PersonalVideo'
import ContactsIcon from '@material-ui/icons/Contacts'
import HistoryIcon from '@material-ui/icons/History'
import PackageIcon from '@material-ui/icons/ViewList'
import DollarIcon from '@material-ui/icons/AttachMoney'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import PageItem from '../components/PageItem'
import ButtonLink from '../components/ButtonLink'
import { Typography } from '@material-ui/core'
import { useApolloClient } from '@apollo/client'
import { SetTutorActiveStateMutation, SetTutorActiveStateMutationVariables, GetTutorsQuery, GetTutorsQueryVariables } from '../gql/__generated__'
import useFallibleEffect from '../utils/fallibleEffect'
import usePagination from '../utils/pagination'

const styles = {
  pages: {
    display: 'flex',
  },
}

type Props = {} & WithStyles

function TutorListPage({ classes }: Props) {
  const client = useApolloClient()
  
  const [tutors, setTutors] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const { addToCount, pagination } = usePagination({
    setIsLoaded,
    onUpdate: async (r, p) => { await onUpdate(r, p) },
    rowsPerPageOptions: [200]
  })

  const getTutors = async (first: number, offset: number) => {
    const result = await client.query<GetTutorsQuery, GetTutorsQueryVariables>({
      query: getTutorsQuery,
      variables: {
        first,
        offset
      }
    })
    return result.data.getTutors
  }

  const handleClick = async (tutorId: string, isActive: boolean) => {
    await client.mutate<SetTutorActiveStateMutation, SetTutorActiveStateMutationVariables>({
      mutation: setTutorActiveStateMutation,
      variables: {
        tutorId,
        isActive,
      },
    })
    await onUpdate(pagination.rowsPerPage, pagination.page)
    NotificationService.showSuccess('Tutor active status successfully changed')
  }

  const onUpdate = async (rowsPerPage = pagination.rowsPerPage, page = pagination.page) => {
    const tutors = await getTutors(rowsPerPage, rowsPerPage * page)
    setTutors(tutors as any)
    addToCount(tutors.length)
  }
  useFallibleEffect(onUpdate, setIsLoaded, [])

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'User',
      accessor: (item: any) => `${item.user.firstName} ${item.user.lastName}`,
      link: (item: any) => `/profile/${item.id}`,
    },
    {
      Header: 'Url',
      accessor: (item: any) => <a href={`${process.env.REACT_APP_BASE_URL}t/${item.slug}`}>Url</a>,
    },
    {
      Header: 'Email',
      accessor: (item: any) => {
        const passwordCredential = item.user.credentials.find((credential: any) => credential.type === 'Password')
        return passwordCredential ? passwordCredential.identifier : ''
      },
    },
    {
      Header: 'pages',
      accessor: (item: any) => {
        return (
          <div className={classes.pages}>
            <PageItem icon={<MoneyIcon />} title='Wallets' link={`wallet/${item.id}`} />
            <PageItem icon={<PhoneIcon />} title='Calls' link={`profile/${item.id}`} />
            <PageItem icon={<StarIcon />} title='Ratings' link={`ratings/${item.id}`} />
            <PageItem icon={<MessageIcon />} title='Messages' link={`conversations/${item.id}`} />
            <PageItem icon={<VideoIcon />} title='Previews' link={`previews/${item.id}`} />
            <PageItem icon={<ContactsIcon />} title='Contacts' link={`contacts/${item.id}`} />
            <PageItem icon={<HistoryIcon />} title='Online status history' link={`status-history/${item.id}`} />
            <PageItem icon={<PackageIcon />} title='Packages' link={`packages/${item.id}`} />
            <PageItem icon={<DollarIcon />} title='Payouts' link={`payouts/${item.id}`} />
          </div>
        )
      },
    },
    {
      Header: 'actions',
      accessor: (item: any) => {
        return (
          <>
            <Button
              color={item.isActive ? 'primary' : 'secondary'}
              variant='outlined'
              size='small'
              onClick={() => handleClick(item.id, !item.isActive)}
            >
              {item.isActive ? 'Deactivate' : 'Make active'}
            </Button>
            <Button color='primary' variant='contained' size='small' href={`/tutor/${item.id}`}>
              Edit
            </Button>
          </>
        )
      },
    },
  ]

  return (
    <Layout>
      <Typography variant='h4'>Tutors</Typography>
      <p>
        <ButtonLink link='/tutor/create'>Create Tutor</ButtonLink>
        <ButtonLink link='/tutors/online'>Online Tutors</ButtonLink>
      </p>

      {!isLoaded ? (
        <CircularProgress size={20} thickness={5} />
      ) : (
        <GenericTable data={tutors} pagination={pagination} columns={columns} />
      )}
    </Layout>
  )
}


export default withStyles(styles)(TutorListPage)
