import gql from 'graphql-tag'

export default gql`
  mutation createCoupon($data: CouponInput!) {
    createCoupon(data: $data) {
      id
      createdAt
      canceledAt
      code
      expiryDate
      multiAccount
      usesAvailable
      usesPerAccount
      note
      package {
        id
        packageId
      }
    }
  }
`
