import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import { User } from '../types'
import { Button } from '@material-ui/core'
import sendNewPasswordMutation from '../gql/mutations/sendNewPassword'
import Typography from '@material-ui/core/Typography'
import NotificationService from '../services/NotificationService'
import { getApolloContext } from '@apollo/client'

type Props = WithStyles<typeof styles> & {
  user: User
}

const styles = {
  card: {
    marginBottom: 20
  }
}

class SendNewPassword extends React.Component<Props> {
  state = {
    sent: false
  }
  static contextType = getApolloContext()
  context!: React.ContextType<ReturnType<typeof getApolloContext>>

  handleClick = async (identifier: string) => {
    try {
      await this.context.client!.mutate({
        mutation: sendNewPasswordMutation,
        variables: {
          identifier
        }
      })
      this.setState({ sent: true })
    } catch (err) {
      NotificationService.showError('Send new password failed')
    }
  }

  render() {
    const { classes, user } = this.props
    const sentComponent = () => {
      if (this.state.sent) {
        return <Typography variant="body1">New password has been e-mailed to the user</Typography>
      }
    }

    return (
      <Card classes={{ root: classes.card }}>
        <CardContent>
          <Button variant="contained" color="secondary" onClick={() => this.handleClick(user.credentials[0].identifier)}>
            Send new password
          </Button>
          {sentComponent()}
        </CardContent>
      </Card>
    )}

}

export default withStyles(styles)(SendNewPassword)
