import { ChangeEvent, FormEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { StyleRulesCallback, withStyles, WithStyles } from '@material-ui/core'
import Input from '@material-ui/core/Input'
import IconButton from '@material-ui/core/IconButton/index'
import SearchIcon from '@material-ui/icons/Search'
import findUserByEmailQuery from "../gql/queries/findUserByEmail";
import { useApolloClient } from '@apollo/client'
import { FindUserByEmailQuery, FindUserByEmailQueryVariables } from '../gql/__generated__'

const styles: StyleRulesCallback<any, any> = theme => ({
  input: {
    margin: '10px',
    backgroundColor: theme.palette.primary.light,
    width: 300
  },
  inputText: {
    padding: 10
  }
})

type Props = WithStyles

function Search({ classes }: Props) {
  const navigate = useNavigate()
  const client = useApolloClient()

  const [searchFieldValue, setSearchFieldValue] = useState('')

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    let profileId
    const inputValue = `${searchFieldValue}`
    if (inputValue.startsWith('ST') || inputValue.startsWith('TU')) {
      profileId = searchFieldValue
    }

    const isEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputValue)

    if (isEmail) {
      const result = await client.query<FindUserByEmailQuery, FindUserByEmailQueryVariables>({
        query: findUserByEmailQuery,
        variables: {
          email: inputValue
        }
      })
      profileId = result?.data?.profileId
    }

    if (profileId) {
      navigate(`/profile/${profileId}`)
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchFieldValue(event.target.value)
  }

  return (
    <form onSubmit={handleSubmit} style={{ display: 'flex' }}>
      <Input
        placeholder="Search"
        className={classes.input}
        classes={{ input: classes.inputText }}
        inputProps={{
          'aria-label': 'Description',
        }}
        type="search"
        onChange={handleChange}
      />
      <IconButton color="inherit" onClick={handleSubmit}>
        <SearchIcon />
      </IconButton>
    </form>
  )
}

export default withStyles(styles)(Search)
