import gql from 'graphql-tag'

export default gql`
  query getAllCoupons($filters: CouponFiltersInput) {
    getAllCoupons(filters: $filters) {
      id
      code
    }
  }
`
