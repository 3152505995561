import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import Cookie from 'js-cookie'
import { Route, Routes, Navigate } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import CssBaseline from '@material-ui/core/CssBaseline'
import { GraphQLClient } from './Api'
import LoginPage from './pages/LoginPage'
import OAuthGooglePage from './pages/OAuthGooglePage'
import TutorListPage from './pages/TutorListPage'
import TutorUpdatePage from './pages/TutorUpdatePage'
import VerifyUsersPage from './pages/VerifyUsersPage'
import theme from './Theme'
import StudentsListPage from './pages/StudentsListPage'
import ProfileDetailPage from './pages/ProfileDetailPage'
import WalletDetailPage from './pages/WalletDetailPage'
import RatingsPage from './pages/RatingsPage'
import AwaitingVerificationListPage from './pages/AwaitingVerificationListPage'
import GenerateTokenPage from './pages/GenerateTokenPage'
import DashboardPage from './pages/DashboardPage'
import TopicsPage from './pages/TopicsPage'
import VoucherPage from './pages/VoucherPage'
import RatingDetailPage from './pages/RatingDetailPage'
import ConversationsListPage from './pages/ConversationsListPage'
import ConversationDetailPage from './pages/ConversationDetailPage'
import ContactsPage from './pages/ContactsPage'
import OnlineStatusHistoryPage from './pages/OnlineStatusHistoryPage'
import SubscriptionsPage from './pages/SubscriptionsPage'
import OnlineTutorsPage from './pages/OnlineTutorsPage'
import BanPage from './pages/BanPage'
import PackagesPage from './pages/PackagesPage'
import ReferralsPage from './pages/ReferralsPage'
import QuizPage from './pages/QuizzesPage'
import PackageCreatePage from './pages/PackageCreatePage'
import ShiftsPage from './pages/ShiftsPage'
import 'react-big-calendar/lib/sass/styles.scss'
import GroupListPage from './pages/GroupListPage'
import GroupCreatePage from './pages/GroupCreatePage'
import GroupDetailPage from './pages/GroupDetailPage'
import GroupAddMemberPage from './pages/GroupAddMemberPage'
import CouponsPage from './pages/CouponsPage'
import TherapistsPage from "./pages/TherapistsPage";

const client = GraphQLClient()
const muiTheme = createTheme(theme)
const routes = ['/login', '/_oauth/google']

const App = () => {
  const [loading, setLoading] = useState(true)
  const adminToken = Cookie.get('admin_token')
  const isAuthRoute = routes.includes(window.location.pathname)

  useEffect(() => {
    if (adminToken) {
      setLoading(false)
    }
  }, [adminToken, isAuthRoute])

  return (
    <ApolloProvider client={client}>
      <MuiThemeProvider theme={muiTheme}>
        <CssBaseline />
        {!adminToken && !isAuthRoute && <Navigate to='/login' />}
        <Routes>
          <Route path='/login' element={ <LoginPage/> } />
          <Route path='/_oauth/google' element={ <OAuthGooglePage/> } />
          {!loading && (
            <>
              <Route path='/' element={ <DashboardPage/> } />
              <Route path='/tutors' element={ <TutorListPage/> } />
              <Route path='/therapists/:studentId' element={ <TherapistsPage/> } />
              <Route path='/students' element={ <StudentsListPage/> } />
              <Route path='/tutor/create' element={ <TutorUpdatePage/> } />
              <Route path='/tutor/:tutorId' element={ <TutorUpdatePage/> } />
              <Route path='/profile/:profileId' element={ <ProfileDetailPage/> } />
              <Route path='/verify-users' element={ <VerifyUsersPage/> } />
              <Route path='/awaiting-verification' element={ <AwaitingVerificationListPage/> } />
              <Route path='/wallet/:profileId' element={ <WalletDetailPage/> } />
              <Route path='/ratings/:profileId' element={ <RatingsPage/> } />
              <Route path='/awaiting-verification/:identifier' element={ <GenerateTokenPage/> } />
              <Route path='/topics' element={ <TopicsPage/> } />
              <Route path='/voucher' element={ <VoucherPage/> } />
              <Route path='/coupons' element={ <CouponsPage/> } />
              <Route path='/rating/:ratingId' element={ <RatingDetailPage/> } />
              <Route path='/conversations/:profileId' element={ <ConversationsListPage/> } />
              <Route path='/conversation/:conversationId' element={ <ConversationDetailPage/> } />
              <Route path='/contacts/:profileId' element={ <ContactsPage/> } />
              <Route path='/status-history/:profileId' element={ <OnlineStatusHistoryPage/> } />
              <Route path='/subscriptions/:profileId' element={ <SubscriptionsPage/> } />
              <Route path='/tutors/online' element={ <OnlineTutorsPage/> } />
              <Route path='/ban/:profileId' element={ <BanPage/> } />
              <Route path='/packages/create' element={ <PackageCreatePage/> } />
              <Route path='/packages/:profileId' element={ <PackagesPage/> } />
              <Route path='/referrals/:profileId' element={ <ReferralsPage/> } />
              <Route path='/quizzes/:profileId' element={ <QuizPage/> } />
              <Route path='/shifts' element={ <ShiftsPage/> } />
              <Route path='/groups' element={ <GroupListPage/> } />
              <Route path='/groups/create' element={ <GroupCreatePage/> } />
              <Route path='/groups/:groupId' element={ <GroupDetailPage/> } />
              <Route path='/groups/:groupId/add' element={ <GroupAddMemberPage/> } />
            </>
          )}
        </Routes>
      </MuiThemeProvider>
    </ApolloProvider>
  )
}

export default App
