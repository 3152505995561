import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { FormikHelpers } from 'formik'

import Layout from '../layouts/Layout'
import { Alert, GenericForm, RouterLink } from '../components'
import GroupMemberSchema, { initialValues, validationSchema, Values } from './formSchemas/GroupMemberSchema'
import NotificationService, { MessageType } from '../services/NotificationService'
import CREATE from '../gql/mutations/inviteGroupMember'
import { GroupAddMemberQuery, GroupAddMemberQueryVariables, InviteGroupMemberMutation, InviteGroupMemberMutationVariables } from '../gql/__generated__'
import QUERY from '../gql/queries/groupAddMemberQuery.api'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useNavigate, useParams } from 'react-router'
import { useMutation, useQuery } from '@apollo/client'

type Props = {}

const GroupAddMemberPage = (_props: Props) => {
  const groupId = useParams().groupId!
  const navigate = useNavigate()
  
  const { data, loading, error } = useQuery<GroupAddMemberQuery, GroupAddMemberQueryVariables>(QUERY, {
    variables: {
      groupId: groupId,
    },
  })
  const { group = null } = data || {}
  const loaded = !loading && !error
  const [addMember] = useMutation<InviteGroupMemberMutation, InviteGroupMemberMutationVariables>(CREATE)

  const handleSubmit = async (values: Values, actions: FormikHelpers<Values>) => {
    actions.setSubmitting(true)
    try {
      await addMember({
        variables: {
          ...values,
          groupRole: values.groupRole,
          state: values.state,
          groupId: groupId,
        },
      })
      actions.setSubmitting(false)
      NotificationService.show(MessageType.success, 'Saved successfully')
      navigate(`/groups/${groupId}`)
    } catch (err: any) {
      actions.setSubmitting(false)
      NotificationService.showError(err.message)
    }
  }
  return (
    <Layout>
      {loading && <CircularProgress size={20} thickness={5} />}
      {!loading && error && <Alert>{JSON.stringify(error)}</Alert>}
      {loaded && group && (
        <>
          <Box mb={4}>
            <Box mb={2}>
              <Typography variant='h4'>
                Add member to {group.name} group
                <RouterLink to={`/groups/${groupId}`}>
                  <Button style={{ float: 'right' }} variant='contained'>
                    Back to group
                  </Button>
                </RouterLink>
              </Typography>
            </Box>
          </Box>
          <GenericForm
            getInitialValues={() => initialValues}
            schema={GroupMemberSchema}
            submitBtnTitle='Add member'
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          />
        </>
      )}
      {loaded && !group && <Alert>Group not found</Alert>}
    </Layout>
  )
}

export default GroupAddMemberPage
