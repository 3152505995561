import { FormSchema } from '../../components'

const initialValues = {
  amount: 0.01,
  comment: ''
}

const CancelCreditSchema: FormSchema = {
  fieldsets: [
    {
      title: 'Cancel credit ($)',
      fields: [
        {
          label: 'Deduct amount($)',
          accessor: 'amount'
        },
        {
          label: 'comment',
          accessor: 'comment'
        }
      ]
    }
  ]
}

export default CancelCreditSchema

export { initialValues }
