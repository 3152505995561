import React from 'react'
import Layout from '../layouts/Layout'
import getOnlineTutorsQuery from '../gql/queries/getOnlineTutors'
import CircularProgress from '@material-ui/core/CircularProgress'
import { GenericTable } from '../components'
import Button from '@material-ui/core/Button'
import setStatusMutation from '../gql/mutations/setStatus'
import { formatDate } from '../Utils'
import { Tutor } from '../types'
import NotificationService from '../services/NotificationService'
import { getApolloContext } from '@apollo/client'

interface State {
  onlineTutors: Tutor[]
  isLoading: boolean
}

type Props = {} & {}

class OnlineTutorsPage extends React.Component<Props, State> {
  state = {
    onlineTutors: [],
    isLoading: true
  }
  static contextType = getApolloContext()
  context!: React.ContextType<ReturnType<typeof getApolloContext>>

  componentDidMount = async () => {
    await this.getOnlineTutors()
    this.setState({ isLoading: false })
  }

  getOnlineTutors = async (): Promise<void> => {
    const result = await this.context.client!.query({
      query: getOnlineTutorsQuery
    })
    this.setState({ onlineTutors: result.data.getOnlineTutors })
  }

  handleClick = async (userId: string) => {
    try {
      await this.context.client!.mutate({
        mutation: setStatusMutation,
        variables: {
          status: {
            status: 'Offline',
            userId
          }
        }
      })
    } catch (err) {
      NotificationService.showError('Error when setting status')
    }

    await this.getOnlineTutors()
  }

  render() {
    const columns = [
      {
        Header: 'id',
        accessor: 'id'
      },
      {
        Header: 'User',
        accessor: (item: any) => `${item.user.firstName} ${item.user.lastName}`,
        link: (item: any) => `/profile/${item.id}`,
      },
      {
        Header: 'Until',
        accessor: (item: any) => item.user.statuses && formatDate(item.user.statuses[0].until)
      },
      {
        Header: 'Set Offline',
        accessor: (item: any) => {
          return <Button color='primary' variant='contained' size='small' onClick={async () => await this.handleClick(item.user.id)}>Set Offline</Button>
        }
      }
    ]
    if (this.state.isLoading) {
      return (
        <Layout>
          <CircularProgress size={20} thickness={5} />
        </Layout>
      )
    }

    return (
      <Layout>
        <GenericTable title="Online Tutors" data={this.state.onlineTutors} columns={columns}/>
      </Layout>
    )
  }
}

export default OnlineTutorsPage
