import gql from 'graphql-tag'

export default gql`
  query TutorUpdateQuery($id: String!) {
    adminGetTutor(id: $id) {
      id
      intros {
        language
        introduction
        motto
      }
      rating
      topics {
        id
        name
        description
      }
      user {
        id
        firstName
        lastName
        countryCode
        middleName
        gender
        birthday
        timezone
        image
        credentials {
          identifier
        }
      }
    }
  }
`
