import React from 'react'
import Layout from '../layouts/Layout'
import { GenericTable } from '../components'
import CircularProgress from '@material-ui/core/CircularProgress'
import { GenericForm } from '../components/GenericForm'
import { formatDate } from '../Utils'
import getVouchers from '../gql/queries/getVouchers'
import CreateVoucherSchema, { initialValues } from './formSchemas/CreateVoucherSchema'
import NotificationService, { MessageType } from '../services/NotificationService'
import createVoucher from '../gql/mutations/createVoucher'
import { CreateVoucherMutation, CreateVoucherMutationVariables, GetVouchersQuery, GetVouchersQueryVariables, VoucherInput } from '../gql/__generated__'
import { Typography } from '@material-ui/core'
import { getApolloContext } from '@apollo/client'

interface State {
  vouchers: any[]
  isLoaded: boolean
}

type Props = {}

class VoucherPage extends React.Component<Props, State> {
  state = {
    vouchers: [],
    isLoaded: false,
  }
  static contextType = getApolloContext()
  context!: React.ContextType<ReturnType<typeof getApolloContext>>

  getVouchers = async () => {
    const result = await this.context.client!.query<GetVouchersQuery, GetVouchersQueryVariables>({
      query: getVouchers,
    })
    this.setState({ vouchers: result.data.getVouchers, isLoaded: true })
  }

  componentDidMount = async () => {
    await this.getVouchers()
  }

  getInitialValues = () => initialValues

  handleSubmit = async (values: any) => {
    if (values.creditAmount === '') {
      NotificationService.show(MessageType.error, 'Credit amount needs to be entered')
      return
    }

    const voucher: VoucherInput = {
      creditAmount: Number(values.creditAmount),
      type: values.type,
    }

    if (values.code) {
      voucher.code = values.code
    }

    if (values.maxRedemptions) {
      voucher.maxRedemptions = Number(values.maxRedemptions)
    }

    if (values.expiration) {
      voucher.expiration = values.expiration
    }

    if (values.creditExpirationAbsolute) {
      voucher.creditExpirationAbsolute = values.creditExpirationAbsolute
    }

    if (values.creditExpirationRelative) {
      voucher.creditExpirationRelative = values.creditExpirationRelative
    }

    if (values.bonusAmountPercent) {
      voucher.bonusAmountPercent = Number(values.bonusAmountPercent)
    }

    try {
      await this.context.client!.mutate<CreateVoucherMutation, CreateVoucherMutationVariables>({
        mutation: createVoucher,
        variables: {
          data: voucher,
        },
      })
      NotificationService.show(MessageType.success, 'Voucher successfully created')
      await this.getVouchers()
      return true
    } catch (err: any) {
      NotificationService.show(MessageType.error, err.message)
    }
  }

  render() {
    const columns = [
      {
        Header: 'Credit amount',
        accessor: (item: any) => `${item.creditAmount} $`,
      },
      {
        Header: 'Code',
        accessor: 'code',
      },
      {
        Header: 'Max redemptions',
        accessor: 'maxRedemptions',
      },
      {
        Header: 'Redemptions',
        accessor: 'redemptions',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Bonus %',
        accessor: 'bonusAmountPercent',
      },
      {
        Header: 'Voucher expiration',
        accessor: (item: any) => {
          return formatDate(item.expiration)
        },
      },
      {
        Header: 'Credit expiration absolute',
        accessor: (item: any) => {
          return formatDate(item.creditExpirationAbsolute)
        },
      },
      {
        Header: 'Credit expiration relative',
        accessor: 'creditExpirationRelative',
      },
      {
        Header: 'CreatedAt',
        accessor: (item: any) => {
          return formatDate(item.createdAt)
        },
      },
    ]

    if (!this.state.isLoaded) {
      return (
        <Layout>
          <CircularProgress size={20} thickness={5} />
        </Layout>
      )
    }

    return (
      <Layout>
        <Typography variant='h4'>Vouchers</Typography>
        <GenericForm
          getInitialValues={this.getInitialValues}
          schema={CreateVoucherSchema}
          onSubmit={this.handleSubmit}
        />
        <GenericTable title='Vouchers' data={this.state.vouchers} columns={columns} />
      </Layout>
    )
  }
}

export default VoucherPage
